import {
  upsertItemInArrayById,
  useOptimisticallyUpdateInfiniteQueriesWithPredicate,
  useOptimisticallyUpdateQueriesWithPredicate,
} from '@/src/lib/react-query/utilities';
import { QueryResourceListPage } from '@/src/modules/resources/queries/useQueryResourceList';
import { isTruthy } from '@/src/utils/guards';
import { useQueryClient } from '@tanstack/react-query';
import { resourceQueryPredicates } from '../../resources/queries/resourceQueryPredicates';
import { FilteredFdocs, ResourceDetail } from '../../resources/resources.types';
import { Comment } from '../comments.types';
import { commentQueryPredicates } from '../queries/commentQueryPredicates';
import { mutateResourcePinnedComment } from './mutateResourcePinnedComment';

type CommentWithOptionalProperties = Partial<Omit<Comment, 'id' | 'resourceId'>> &
  Pick<Comment, 'id' | 'resourceId'>;
type CommentMutator = (comment?: Comment) => Comment | undefined;

export const useQueryCacheCommentHelpers = () => {
  const queryClient = useQueryClient();

  const optimisticallyUpdateQueries = useOptimisticallyUpdateQueriesWithPredicate();
  const optimisticallyUpdateInfiniteQueries = useOptimisticallyUpdateInfiniteQueriesWithPredicate();

  return {
    updateCachedComment: (
      commentWithUpdatedProperties: CommentWithOptionalProperties,
      mutator?: CommentMutator,
    ) => {
      queryClient.cancelQueries({
        predicate: (q) =>
          commentQueryPredicates.commentByResourceId(q, commentWithUpdatedProperties.resourceId) ||
          resourceQueryPredicates.resourceDetail(q, commentWithUpdatedProperties.resourceId) ||
          resourceQueryPredicates.resourceListAny(q),
        type: 'active',
      });

      /**
       * updating the list of comments in the resource where the comment is present
       */
      const optimisticallyUpdateCommentQueries = optimisticallyUpdateQueries<Comment[]>(
        (q) =>
          commentQueryPredicates.commentByResourceId(q, commentWithUpdatedProperties.resourceId),
        (data) =>
          mutator
            ? data
                ?.map((comment) =>
                  comment.id === commentWithUpdatedProperties.id ? mutator(comment) : comment,
                )
                .filter(isTruthy)
            : upsertItemInArrayById(data ?? [], commentWithUpdatedProperties, {
                createIfNotFound: false,
              }),
      );

      /**
       * updating the resource directly (because of the pinned comment)
       */
      const optimisticallyUpdateResourceQueries = optimisticallyUpdateQueries<ResourceDetail>(
        (q) => resourceQueryPredicates.resourceDetail(q, commentWithUpdatedProperties.resourceId),
        (resource) =>
          (resource?.commentPinned &&
            mutateResourcePinnedComment(resource, {
              ...resource.commentPinned,
              ...commentWithUpdatedProperties,
              ...mutator?.(resource.commentPinned),
            })) ??
          resource,
      );

      /**
       * Update the list of resources where the resource is present to update the pinned comment
       * @TODO search queries
       */
      const optimisticallyUpdateFilterAndSearchAllQueries =
        optimisticallyUpdateInfiniteQueries<QueryResourceListPage>({
          predicate: resourceQueryPredicates.resourceList,
          pageUpdater: (page) => ({
            ...page,
            resources: page.resources.map((resource) =>
              resource.id === commentWithUpdatedProperties.resourceId && resource.commentPinned
                ? mutateResourcePinnedComment(resource, {
                    ...resource.commentPinned,
                    ...commentWithUpdatedProperties,
                    ...mutator?.(resource.commentPinned),
                  })
                : resource,
            ),
          }),
        });

      return {
        optimisticallyUpdateCommentQueries,
        optimisticallyUpdateResourceQueries,
        optimisticallyUpdateFilterAndSearchAllQueries,
        resetCacheToPreOptimisticState: () => {
          optimisticallyUpdateCommentQueries.resetQueriesData();
          optimisticallyUpdateResourceQueries.resetQueriesData();
          optimisticallyUpdateFilterAndSearchAllQueries.resetQueriesData();
        },
        invalidateQueries: () => {
          optimisticallyUpdateCommentQueries.invalidateQueries();
          optimisticallyUpdateResourceQueries.invalidateQueries();
          optimisticallyUpdateFilterAndSearchAllQueries.invalidateQueries();
        },
      };
    },
    deleteCachedComment: (commentId: string, resourceId: string) => {
      queryClient.cancelQueries({
        predicate: (q) =>
          commentQueryPredicates.commentByResourceId(q, resourceId) ||
          resourceQueryPredicates.resourceDetail(q, resourceId) ||
          resourceQueryPredicates.resourceListAny(q),
        type: 'active',
      });

      /**
       * updating the list of comments in the resource where the comment is present
       */
      const optimisticallyUpdateCommentQueries = optimisticallyUpdateQueries<Comment[]>(
        (q) => commentQueryPredicates.commentByResourceId(q, resourceId),
        (data) => data?.filter((comment) => comment.id !== commentId),
      );

      /**
       * updating the resource directly (because of the pinned comment)
       */
      const optimisticallyUpdateResourceQueries = optimisticallyUpdateQueries<ResourceDetail>(
        (q) => resourceQueryPredicates.resourceDetail(q, resourceId),
        (resource) =>
          resource && resource.commentPinned?.id === commentId
            ? {
                ...resource,
                commentPinned: null,
                // not accurate, what if removing comment which is not pinned?
                commentCount: Math.max(0, (resource.commentCount ?? 0) - 1),
              }
            : resource,
      );

      /**
       * Update the list of resources where the resource is present to update the pinned comment
       */
      const optimisticallyUpdateFilterAndSearchAllQueries =
        optimisticallyUpdateInfiniteQueries<FilteredFdocs>({
          predicate: resourceQueryPredicates.resourceListAny,
          pageUpdater: (page) => ({
            ...page,
            results: page.results.map((resource) =>
              resource.id === resourceId
                ? {
                    ...resource,
                    commentPinned:
                      resource.commentPinned?.id === commentId ? null : resource.commentPinned,
                    // not accurate, what if removing comment which is not pinned?
                    commentCount: Math.max(0, (resource.commentCount ?? 0) - 1),
                  }
                : resource,
            ),
          }),
        });

      return {
        optimisticallyUpdateCommentQueries,
        optimisticallyUpdateResourceQueries,
        optimisticallyUpdateFilterAndSearchAllQueries,
        resetCacheToPreOptimisticState: () => {
          optimisticallyUpdateCommentQueries.resetQueriesData();
          optimisticallyUpdateResourceQueries.resetQueriesData();
          optimisticallyUpdateFilterAndSearchAllQueries.resetQueriesData();
        },
        invalidateQueries: () => {
          optimisticallyUpdateCommentQueries.invalidateQueries();
          optimisticallyUpdateResourceQueries.invalidateQueries();
          optimisticallyUpdateFilterAndSearchAllQueries.invalidateQueries();
        },
      };
    },
    addCachedComment: (comment: Comment) => {
      queryClient.cancelQueries({
        predicate: (q) =>
          commentQueryPredicates.commentByResourceId(q, comment.resourceId) ||
          resourceQueryPredicates.resourceDetail(q, comment.resourceId) ||
          resourceQueryPredicates.resourceListAny(q),
        type: 'active',
      });

      /**
       * updating the list of comments in the resource where the comment is present
       */
      const optimisticallyUpdateCommentQueries = optimisticallyUpdateQueries<Comment[]>(
        (q) => commentQueryPredicates.commentByResourceId(q, comment.resourceId),
        (data) => upsertItemInArrayById(data ?? [], comment, { createIfNotFound: true }),
      );

      /**
       * updating the resource directly (because of the pinned comment)
       */
      const optimisticallyUpdateResourceQueries = optimisticallyUpdateQueries<ResourceDetail>(
        (q) => resourceQueryPredicates.resourceDetail(q, comment.resourceId),
        (resource) => (resource ? mutateResourcePinnedComment(resource, comment) : resource),
      );

      /**
       * Update the list of resources where the resource is present to update the pinned comment
       * @TODO missing update search
       */
      const optimisticallyUpdateFilterAndSearchAllQueries =
        optimisticallyUpdateInfiniteQueries<QueryResourceListPage>({
          predicate: resourceQueryPredicates.resourceList,
          pageUpdater: (page) => ({
            ...page,
            resources: page.resources.map((resource) =>
              resource.id === comment.resourceId
                ? mutateResourcePinnedComment(resource, comment)
                : resource,
            ),
          }),
        });

      return {
        optimisticallyUpdateCommentQueries,
        optimisticallyUpdateResourceQueries,
        optimisticallyUpdateFilterAndSearchAllQueries,
        resetCacheToPreOptimisticState: () => {
          optimisticallyUpdateCommentQueries.resetQueriesData();
          optimisticallyUpdateResourceQueries.resetQueriesData();
          optimisticallyUpdateFilterAndSearchAllQueries.resetQueriesData();
        },
        invalidateQueries: () => {
          optimisticallyUpdateCommentQueries.invalidateQueries();
          optimisticallyUpdateResourceQueries.invalidateQueries();
          optimisticallyUpdateFilterAndSearchAllQueries.invalidateQueries();
        },
      };
    },
    replaceCommentInCache: (oldComment: Comment, newComment: Comment) => {
      queryClient.cancelQueries({
        predicate: (q) =>
          commentQueryPredicates.commentByResourceId(q, oldComment.resourceId) ||
          resourceQueryPredicates.resourceDetail(q, oldComment.resourceId) ||
          resourceQueryPredicates.resourceListAny(q),
        type: 'active',
      });

      /**
       * updating the list of comments in the resource where the comment is present
       */
      const optimisticallyUpdateCommentQueries = optimisticallyUpdateQueries<Comment[]>(
        (q) => commentQueryPredicates.commentByResourceId(q, oldComment.resourceId),
        (data) =>
          (data ?? []).map((comment) => (comment.id === oldComment.id ? newComment : comment)),
      );

      /**
       * updating the resource directly (because of the pinned comment)
       */
      const optimisticallyUpdateResourceQueries = optimisticallyUpdateQueries<ResourceDetail>(
        (q) => resourceQueryPredicates.resourceDetail(q, oldComment.resourceId),
        (resource) =>
          (resource?.commentPinned && mutateResourcePinnedComment(resource, newComment)) ??
          resource,
      );

      /**
       * Update the list of resources where the resource is present to update the pinned comment
       * @TODO search
       */
      const optimisticallyUpdateFilterAndSearchAllQueries =
        optimisticallyUpdateInfiniteQueries<QueryResourceListPage>({
          predicate: resourceQueryPredicates.resourceList,
          pageUpdater: (page) => ({
            ...page,
            resources: page.resources.map((resource) =>
              resource.id === oldComment.resourceId && resource.commentPinned
                ? mutateResourcePinnedComment(resource, newComment)
                : resource,
            ),
          }),
        });

      return {
        optimisticallyUpdateCommentQueries,
        optimisticallyUpdateResourceQueries,
        optimisticallyUpdateFilterAndSearchAllQueries,
        resetCacheToPreOptimisticState: () => {
          optimisticallyUpdateCommentQueries.resetQueriesData();
          optimisticallyUpdateResourceQueries.resetQueriesData();
          optimisticallyUpdateFilterAndSearchAllQueries.resetQueriesData();
        },
        invalidateQueries: () => {
          optimisticallyUpdateCommentQueries.invalidateQueries();
          optimisticallyUpdateResourceQueries.invalidateQueries();
          optimisticallyUpdateFilterAndSearchAllQueries.invalidateQueries();
        },
      };
    },
  };
};
