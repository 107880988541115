import { ResourceDetail } from '@/src/modules/resources/resources.types';
import { isResourceDetailNotepad } from '@/src/modules/resources/utils/resourceDetailGuards';
import { Fdoc } from '@/src/types/api';
import { EditorJSData } from '@fabric/woody-client';

export const mutateNotepadContent = (resource: Fdoc, content: EditorJSData): Fdoc => {
  if (resource.type !== 'notepad') {
    return resource;
  }

  return {
    ...resource,
    data: {
      ...resource.data,
      editorjs: content,
      createdAt: new Date(),
      modifiedAt: new Date(),
    },
  };
};

export const mutateNotepadContentV2 = (
  resource: ResourceDetail,
  content: EditorJSData,
): ResourceDetail => {
  if (isResourceDetailNotepad(resource)) {
    return {
      ...resource,
      data: {
        ...resource.data,
        preview: {
          ...resource.data.preview,
          ...(content as any), // for now
        },
      },
    };
  }

  return resource;
};
