import { useResponsive } from '@/src/hooks/responsive';
import useCopyToClipboard from '@/src/hooks/useCopyToClipboard';
import useDownloadFdoc from '@/src/hooks/useDownloadFdoc';
import { useAskAssistantItem } from '@/src/modules/assistant/hooks/useAskAssistantItem';
import { useQueryInbox } from '@/src/modules/connections/queries/useQueryInbox';
import {
  IconAssistantFace,
  IconCopy,
  IconDownload,
  IconEye,
  IconFolder,
  IconLinkWindow,
  IconMove,
  IconTag,
  IconTrash,
  IconUserPlus,
} from '@/src/modules/icons';
import LabelSelector from '@/src/modules/labels/components/LabelSelector/LabelSelector';
import { ApiColorLabel } from '@/src/modules/labels/labels.types';
import { useQueryMembersByListId } from '@/src/modules/members/queries/useQueryMembersByListId';
import { useMutationDeleteResourcesById } from '@/src/modules/resources/mutations/useMutationDeleteResourcesById';
import { isImageFdoc, isStoredFileFdoc } from '@/src/modules/resources/utils/resourceTypes';
import { Space } from '@/src/modules/spaces/spaces.types';
import { ContextMenu } from '@/src/modules/ui/components/ContextMenu/ContextMenu';
import { DropdownItemIconContainer } from '@/src/modules/ui/components/DropdownMenu/Item';
import { setGlobalSelectionOptions } from '@/src/store/ui';
import { Fdoc } from '@/src/types/api';
import { OptimisticDraft } from '@/src/types/draftable';
import { EnterIcon } from '@radix-ui/react-icons';
import { nativeWindow } from '@todesktop/client-core';
import { publish } from '@todesktop/client-ipc';
import { useRouter } from 'next/router';
import React from 'react';

type ResourcePreviewContextMenuProps = {
  children: React.ReactElement;
  onItemClickAction?: (e?: React.MouseEvent) => void;
  onGoToSourceClick: (e?: React.MouseEvent) => void;
  selectionMode?: boolean;
  selected?: boolean;
  hideDelete?: boolean;
  overrideOnDelete?: (e?: React.MouseEvent) => void;
  hideMove?: boolean;
  sourceUrl?: string;
  setShowingShareModal?: (showing: boolean) => void;
  style?: React.CSSProperties;
  openTagModal?: (e: React.MouseEvent) => void;

  fdoc?: OptimisticDraft<Fdoc>;
  colorLabels?: ApiColorLabel[];
  list?: Space;
  contextDisabled?: boolean;
};

export const ResourcePreviewContextMenu: React.FC<ResourcePreviewContextMenuProps> = ({
  contextDisabled,
  onItemClickAction,
  onGoToSourceClick,
  selectionMode = false,
  selected = false,
  hideDelete = false,
  overrideOnDelete,
  hideMove = false,
  setShowingShareModal,
  sourceUrl,
  fdoc,
  colorLabels,
  list,
  openTagModal,
  children,
}) => {
  const [, copy] = useCopyToClipboard();
  const { inboxRoot } = useQueryInbox();
  const router = useRouter();
  const askAssistantItem = useAskAssistantItem();

  const isResourceDownloadable = (isStoredFileFdoc(fdoc) || isImageFdoc(fdoc)) && !!fdoc.fileUrl;

  const { isCurrentUserMember } = useQueryMembersByListId(list?.id);

  /**
   * readonly when on specific list as a visitor
   */
  const readOnly = (list?.id && !isCurrentUserMember) || false;

  const { isMobileView } = useResponsive();

  const handleCopySourceUrl = () => {
    if (!sourceUrl) return;

    copy(sourceUrl);
  };

  const handleAddToSpace = () => {
    if (!fdoc?.id) return;

    if (router.pathname === '/desktop-global-search') {
      try {
        publish('add-to-space', { fdoc });
      } catch (e) {
        window.open(`fabric://add-to-space?id=${fdoc?.id}`, '_blank');
      }
      nativeWindow.close();
      return;
    }

    setGlobalSelectionOptions({
      selectedFdocsIds: [fdoc.id],
    });
  };

  const downloadFdoc = useDownloadFdoc();
  const downloadFdocHandler = () => {
    if (!fdoc) return;
    downloadFdoc(fdoc);
  };

  const openEnclosingSpace = () => {
    let path = `/folders/${fdoc?.parentResourceId}`;
    if (fdoc?.list && fdoc?.list === fdoc?.parentResourceId) path = `/spaces/${fdoc?.list}`;
    if (inboxRoot?.id === fdoc?.parentResourceId) path = '/saved';

    if (router.pathname === '/desktop-global-search') {
      try {
        publish('open-path', { path: path });
      } catch (e) {
        window.open(`fabric://open-path?path=${path}`, '_blank');
      }
      nativeWindow.close();
      return;
    }

    router.push(path, undefined, { shallow: true });
  };

  const downloadText = React.useMemo(() => {
    if (fdoc?.type !== 'stored_file') return 'Download';

    if (fdoc.data.contentType?.startsWith('image/')) {
      return 'Download image';
    } else if (fdoc.data.contentType?.startsWith('application/pdf')) {
      return 'Download PDF';
    } else {
      return 'Download file';
    }
  }, [fdoc]);

  const mutationDeleteResources = useMutationDeleteResourcesById();
  const onDelete = () => fdoc && mutationDeleteResources.confirmAndMutateAsync([fdoc.id]);

  const askAssistantItemHandler = () => {
    if (!fdoc) return;

    askAssistantItem(fdoc.id);
  };

  return (
    <ContextMenu
      contextDisabled={contextDisabled}
      previewAnchor={isMobileView}
      content={
        <>
          <ContextMenu.Group>
            {onItemClickAction && (
              <ContextMenu.Item
                onClick={onItemClickAction}
                data-testid="item-context-menu-action-button"
              >
                <DropdownItemIconContainer>
                  <IconEye />
                </DropdownItemIconContainer>
                {fdoc?.type === 'folder' ? 'Open' : 'View'}

                {!isMobileView && (
                  <kbd
                    style={{
                      padding: '4px',
                    }}
                  >
                    <span>space</span>
                  </kbd>
                )}
              </ContextMenu.Item>
            )}
            {isResourceDownloadable && (
              <ContextMenu.Item
                onClick={downloadFdocHandler}
                data-testid="item-context-menu-download-button"
              >
                <DropdownItemIconContainer>
                  <IconDownload />
                </DropdownItemIconContainer>
                {downloadText}
              </ContextMenu.Item>
            )}
            {sourceUrl && (
              <>
                <ContextMenu.Item
                  onClick={onGoToSourceClick}
                  data-testid="item-context-menu-go-to-source-button"
                >
                  <DropdownItemIconContainer>
                    <IconLinkWindow />
                  </DropdownItemIconContainer>
                  Go to original URL{' '}
                  {!isMobileView && (
                    <kbd>
                      <EnterIcon />
                    </kbd>
                  )}
                </ContextMenu.Item>
                <ContextMenu.Item
                  onClick={handleCopySourceUrl}
                  data-testid="item-context-menu-copy-source-button"
                >
                  <DropdownItemIconContainer>
                    <IconCopy />
                  </DropdownItemIconContainer>
                  Copy original URL
                </ContextMenu.Item>
              </>
            )}
            {!readOnly && !hideMove && !fdoc?.listData?.integration && (
              <ContextMenu.Item
                onClick={handleAddToSpace}
                data-testid="item-context-menu-add-to-space"
              >
                <DropdownItemIconContainer>
                  <IconMove />
                </DropdownItemIconContainer>
                Move...
              </ContextMenu.Item>
            )}
            {!readOnly && colorLabels && list && fdoc && !isMobileView && (
              <ContextMenu.ItemRaw>
                <LabelSelector space={list} fdocs={[fdoc]} colorLabels={colorLabels} />
              </ContextMenu.ItemRaw>
            )}
            {((router.pathname !== '/spaces/[listId]' &&
              router.pathname !== '/saved' &&
              router.pathname !== '/folders/[folderId]') ||
              typeof router.query.search === 'string') && (
              <ContextMenu.Item
                onClick={openEnclosingSpace}
                data-testid="item-context-menu-go-to-list-button"
              >
                <DropdownItemIconContainer>
                  <IconFolder />
                </DropdownItemIconContainer>
                Open enclosing space
              </ContextMenu.Item>
            )}
            {!readOnly && (
              <ContextMenu.Item
                onClick={openTagModal}
                data-testid="item-context-menu-edit-tags-button"
              >
                <DropdownItemIconContainer>
                  <IconTag />
                </DropdownItemIconContainer>
                Add/edit tags
              </ContextMenu.Item>
            )}
          </ContextMenu.Group>

          <ContextMenu.Group>
            <ContextMenu.Item
              data-testid="item-context-menu-ask-about-item"
              onClick={askAssistantItemHandler}
            >
              <DropdownItemIconContainer>
                <IconAssistantFace />
              </DropdownItemIconContainer>
              Ask about this item...
            </ContextMenu.Item>
          </ContextMenu.Group>

          {!readOnly && setShowingShareModal && fdoc?.type !== 'folder' && (
            <ContextMenu.Group>
              <ContextMenu.Item
                onClick={() => setShowingShareModal(true)}
                data-testid="item-context-menu-share-button"
              >
                <DropdownItemIconContainer>
                  <IconUserPlus />
                </DropdownItemIconContainer>
                {fdoc?.isDirectShared ? 'Manage sharing' : 'Share'}
              </ContextMenu.Item>
            </ContextMenu.Group>
          )}

          {!readOnly &&
            !fdoc?.listData?.integration &&
            (selected || !selectionMode) &&
            !hideDelete && (
              <ContextMenu.Group>
                <ContextMenu.Item
                  onClick={overrideOnDelete ?? onDelete}
                  data-testid="item-context-menu-delete-button"
                  variant="danger"
                >
                  <DropdownItemIconContainer>
                    <IconTrash />
                  </DropdownItemIconContainer>
                  Delete {fdoc?.type === 'folder' && 'folder'}
                </ContextMenu.Item>
              </ContextMenu.Group>
            )}
        </>
      }
    >
      {children}
    </ContextMenu>
  );
};
