import { ResourceDetail } from '@/src/modules/resources/resources.types';
import { Comment } from '../comments.types';

export const mutateResourcePinnedComment = (
  resource: ResourceDetail,
  comment: Comment,
): ResourceDetail => {
  return {
    ...resource,
    // we replace the pinned comment with the new one only if the comment count is 1 or less
    // and the new comment is created by the same user as the resource
    commentPinned:
      comment.user?.id === resource.user?.id && (resource.commentCount ?? 0) <= 1
        ? {
            ...comment,
            userId: comment.user.id,
          }
        : resource.commentPinned,
  };
};
