import useDownloadFdoc from '@/src/hooks/useDownloadFdoc';
import { ButtonIconDownload } from '@/src/modules/ui/components/button/ButtonIconDownload';
import { PinchZoomImage } from '@/src/modules/ui/components/images/PinchZoomImage';
import { ImageFdoc } from '@/src/types/api';
import { createPortal } from 'react-dom';
import { OptimisticDraft } from '../../types/draftable';
import { useExpandedFdocContentContext } from './ExpandedFdocContent';

type MediaContentProps = {
  fdoc: OptimisticDraft<ImageFdoc>;
  imageRef: React.RefObject<HTMLImageElement>;
  disableGestures?: boolean;
};
export function MediaContent({ fdoc, imageRef, disableGestures }: MediaContentProps) {
  const { topHeaderControlsRef } = useExpandedFdocContentContext();
  const downloadFdoc = useDownloadFdoc();
  const handleDownloadFile = async () => {
    if (fdoc.type !== 'image') return;
    downloadFdoc(fdoc);
  };

  const downloadButton =
    topHeaderControlsRef && fdoc.fileUrl
      ? createPortal(<ButtonIconDownload onClick={handleDownloadFile} />, topHeaderControlsRef)
      : null;
  return fdoc.type === 'image' && fdoc.thumbnail?.lg ? (
    <>
      {downloadButton}
      <PinchZoomImage
        src={fdoc.thumbnail?.lg}
        alt={fdoc.data.pageTitle}
        ref={imageRef}
        disableGestures={disableGestures}
      />
    </>
  ) : null;
}
