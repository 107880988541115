import { FolderFdoc } from '@/src/types/api';
import { OptimisticDraft } from '@/src/types/draftable';
import styled from 'styled-components';

import FolderIcon from '@/public/images/icons/Folder.png';
import FolderDeviceIcon from '@/public/images/icons/FolderDevice.png';
import { isDeviceConnectionType } from '@/src/modules/connections/connections.utils';
import { IconFileName } from '@/src/modules/resources/components/ResourcePreview//components/IconFileName';
import { ColorLabel } from '@/src/modules/resources/components/ResourcePreview/components/ColorLabel';
import { ResourcePreviewContentProps } from '@/src/modules/resources/components/ResourcePreview/ResourcePreview.types';
import { containerPreviewCompact } from '@/src/modules/resources/components/ResourcePreview/ResourcePreviewGrid/cssPreviewContainer';
import { cssAspectRatio } from '@/src/modules/resources/components/ResourcePreview/ResourcePreviewGrid/sharedCss';
import { useResourceStoredMetadata } from '@/src/modules/resources/hooks/useResourceStoredMetadata';

interface ResourcePreviewFolderProps {
  resource: OptimisticDraft<FolderFdoc>;
  colorLabels?: ResourcePreviewContentProps['colorLabels'];
}

export const ResourcePreviewFolder: React.FC<ResourcePreviewFolderProps> = ({
  resource,
  colorLabels,
}) => {
  const { title } = useResourceStoredMetadata(resource);
  return (
    <Wrapper data-preview-content-wrapper="folder">
      <ColorLabel absPosition="inside-top-left" colorLabels={colorLabels} resource={resource} />
      {isDeviceConnectionType(resource.listData?.integration) ? (
        <Image src={FolderDeviceIcon.src} alt="Device Folder" draggable={false} />
      ) : (
        <Image src={FolderIcon.src} alt="Folder" draggable={false} />
      )}
      <IconFileName title={title || 'Untitled'} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
  gap: 1rem;
  overflow: hidden;
  justify-content: center;
  padding: 0.75rem;
  border-radius: 1.125rem;
  position: relative;
  padding: 0.75rem;
  ${cssAspectRatio};
`;

/** thumbnail or empty state */

const Image = styled.img`
  max-height: 7rem;
  max-width: 7rem;
  height: auto;
  width: auto;
  ${containerPreviewCompact} {
    max-height: 4rem;
    max-width: 4rem;
  }
`;
