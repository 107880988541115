import EditorPreview from '@/src/components/EditorPreview/EditorPreview';
import { ColorLabel } from '@/src/modules/resources/components/ResourcePreview/components/ColorLabel';
import { ResourcePreviewComponent } from '@/src/modules/resources/components/ResourcePreview/ResourcePreview.types';
import { ResourcePreviewListPreview } from '@/src/modules/resources/components/ResourcePreview/ResourcePreviewList/preview/ResourcePreviewListItemPreview';
import { ResourcePreviewListItemComment } from '@/src/modules/resources/components/ResourcePreview/ResourcePreviewList/ResourcePreviewListItemComment';
import { ResourcePreviewListTitle } from '@/src/modules/resources/components/ResourcePreview/ResourcePreviewList/title/ResourcePreviewListItemTitle';
import { isNotepadFdoc } from '@/src/modules/resources/utils/resourceTypes';
import { Flex } from '@/src/modules/ui/components/Flex';
import { ListItem } from '@/src/modules/ui/components/List/List';
import { P } from '@/src/modules/ui/components/Typography';
import { cssVar } from '@/src/modules/ui/theme/variables';
import { Fdoc } from '@/src/types/api';
import { OptimisticDraft } from '@/src/types/draftable';

import { IconLinkArrow } from '@/src/modules/icons';
import { IconSharedWithPublic } from '@/src/modules/resources/components/IconSharedWithPublic';
import ResourcePreviewLoadingState from '@/src/modules/resources/components/ResourcePreview/components/FdocLoadState';
import { useResourceHoverKeyboardEvents } from '@/src/modules/resources/components/ResourcePreview/hooks/useResourcePreviewKbdEnterOpenExternal';
import { useResourceStoredMetadata } from '@/src/modules/resources/hooks/useResourceStoredMetadata';
import { ButtonIcon } from '@/src/modules/ui/components/Button';
import { LinkExternal } from '@/src/modules/ui/components/Link/LinkExternal';
import { useInViewWithinRadixScrollArea } from '@/src/modules/ui/components/ScrollArea';
import React from 'react';
import styled, { css } from 'styled-components';

type PreviewListVariant = 'notepad';

const getResourcePreviewVariant = (
  resource: OptimisticDraft<Fdoc>,
): PreviewListVariant | undefined => {
  if (isNotepadFdoc(resource)) {
    return 'notepad';
  }

  return undefined;
};

export const ResourcePreviewListItem: ResourcePreviewComponent = (props) => {
  const { resource, children, colorLabels, onOpen, isSelected, isSelectable, ...rest } = props;
  const ref = React.useRef<HTMLDivElement>(null);
  const isInView = useInViewWithinRadixScrollArea(ref);

  const { isProcessing, isLoadingState } = useResourceStoredMetadata(resource);

  const externalLinkRef = React.useRef<HTMLAnchorElement>(null);
  const { onMouseEnter, onMouseLeave } = useResourceHoverKeyboardEvents({
    onOpenExternal: () => externalLinkRef.current?.click(),
    onOpen,
    enabled: !isSelected && isInView,
  });

  return (
    <StyledListItem
      {...rest}
      variant={getResourcePreviewVariant(resource)}
      ref={ref}
      onClick={onOpen}
      isSelected={isSelected}
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
    >
      {isInView && (
        <ListItemInner
          alignItems="center"
          gap="elementsContainer"
          justifyContent="space-between"
          data-selectable-id={
            resource?.isDraft || resource?.listData?.integration || !isSelectable
              ? undefined
              : resource?.id
          }
        >
          <ColorLabel {...props} />
          <PreviewContainer>
            {isLoadingState ? (
              <ResourcePreviewLoadingState isProcessing={isProcessing} />
            ) : (
              <ResourcePreviewListPreview resource={resource} />
            )}
          </PreviewContainer>
          <TitleContainer>
            <ResourcePreviewListTitle resource={resource} />
          </TitleContainer>
          {'editorjs' in resource.data && resource.data.editorjs && (
            <EditorContainer>
              <StyledEditorPreview data={resource.data.editorjs} />
            </EditorContainer>
          )}
          <CommentContainer>
            {resource.commentPinned && (
              <ResourcePreviewListItemComment comment={resource.commentPinned} />
            )}
          </CommentContainer>
          <MetaContainer>
            {resource.isDirectShared && <IconSharedWithPublic />}
            {new Date(resource.createdAt).toLocaleDateString('en-GB', {
              day: 'numeric',
              month: 'numeric',
              year: 'numeric',
            })}
          </MetaContainer>

          {resource.originUrl && (
            <ExternalLinkContainer>
              <ButtonIcon
                variant="bg-primary-reverse"
                as={LinkExternal}
                href={resource.originUrl}
                ref={externalLinkRef}
              >
                <IconLinkArrow style={{ width: '.875rem', height: '.875rem' }} />
              </ButtonIcon>
            </ExternalLinkContainer>
          )}
          {children}
        </ListItemInner>
      )}
    </StyledListItem>
  );
};

const ExternalLinkContainer = styled.div`
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  display: none;
`;

const StyledListItem = styled(ListItem)<{ variant?: PreviewListVariant; isSelected?: boolean }>`
  padding: 0.5rem 1rem;
  position: relative;
  height: 74px;
  cursor: pointer;

  ${(p) =>
    p.isSelected &&
    css`
      border-color: ${cssVar['color-app-primary']};
      position: relative;
      z-index: 10;

      &:after {
        content: '';
        position: absolute;
        background: rgba(42, 55, 238, 0.2);
        display: block;
        border-radius: 4px;
        z-index: 20;
        pointer-events: none;
        inset: 0;
      }
    `}

  ${(p) => {
    switch (p.variant) {
      case 'notepad':
        return css`
          background: ${cssVar['color-bg-note-primary']};
        `;
    }
  }}
  &:hover {
    ${ExternalLinkContainer} {
      display: block;
    }
  }
`;

const ListItemInner = styled(Flex).attrs({ alignItems: 'center', gap: 'elementsContainer' })`
  height: 100%;
  overflow: hidden;
`;

const PreviewContainer = styled.div`
  height: 2.5rem;
  width: 2.5rem;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${cssVar['color-text-secondary']};
`;

const TitleContainer = styled.div`
  width: 100px;
  flex-shrink: 1;
  flex-grow: 1;
`;

const CommentContainer = styled.div`
  width: 250px;
  flex-shrink: 1;
`;

const StyledEditorPreview = styled(EditorPreview).attrs({
  withTruncator: true,
})`
  max-height: 100%;
  * {
    font-size: 0.8125rem !important;
    line-height: 1.125rem;
  }
`;

const EditorContainer = styled.div`
  overflow: hidden;
  max-height: 100%;
  max-width: 250px;
  width: 100px;
  flex-grow: 1;
  height: 55px;
  display: flex;
  align-items: center;
  color: ${cssVar['color-text-placeholder']};
`;

const MetaContainer = styled(P).attrs({
  size: 'xxs',
  color: 'placeholder',
})`
  flex-shrink: 0;
  width: 100px;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  align-items: center;
`;
