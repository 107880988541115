import { createLogger } from '@/src/lib/logger/createLogger';
import { isQueryEnabled } from '@/src/lib/react-query/isQueryEnabled';
import { useWoody } from '@/src/services/woody/woody';
import { NotepadFdoc } from '@/src/types/api';
import { QueryObserverOptions, useQuery, useQueryClient } from '@tanstack/react-query';
import { resourceDetailsQueryKeys } from './resourceDetailsQueryKeys';
import * as Y from 'yjs';
import { useMutationNotepadStateUpdate } from '../mutations/useMutationNotepadStateUpdate';
import { database } from '@/src/lib/storage/global';
import { PersistedQuery } from '@tanstack/react-query-persist-client';
import { persisterQueriesStoreName } from '@/src/lib/react-query/persister';
import { captureException } from '@sentry/nextjs';

/**
 * Fetch a notepad yjs state.
 *
 * @param fdoc
 * @param queryOptions
 * @returns
 */
export const useQueryNotepadResourceState = (
  fdoc?: NotepadFdoc,
  queryOptions?: Partial<QueryObserverOptions<Uint8Array>>,
) => {
  const queryClient = useQueryClient();
  const { client } = useWoody();

  const mutationUpdateNotepadState = useMutationNotepadStateUpdate();

  const query = useQuery({
    queryKey: resourceDetailsQueryKeys.notepadState(fdoc),
    queryFn: async (params) => {
      const safeResourceId = params.queryKey[1] as string;

      const logger = createLogger(`notepad-state-${safeResourceId}`);

      const response = await client.v2({
        endpoint: '/v2/notepads/{resourceId}/state',
        params: {
          resourceId: safeResourceId,
        },
      });

      //          ╭─────────────────────────────────────────────────────────╮
      //          │    Data loss prevention, instead of overwritting the    │
      //          │  persisted data we always try to merge with the stored  │
      //          │   data. This means if the user was unable to save for   │
      //          │  some reason before it will not be overwitten with the  │
      //          │  remote data fully, instead it will safely merge it to  │
      //          │   then be updated into the remote/woody notepad using   │
      //          │          the SYNC EP or YJS server connection.          │
      //          ╰─────────────────────────────────────────────────────────╯

      const key = resourceDetailsQueryKeys.notepadState(fdoc);

      const oldData =
        // Try to get it directly from react query if available
        queryClient.getQueryData<Uint8Array>(key) ??
        // Otherwise we fall back to getting it directly from the local DB since it could have been GC'ed
        ((
          await database?.getItem<PersistedQuery>(
            persisterQueriesStoreName,
            `tanstack-query-${JSON.stringify(key)}`,
          )
        )?.state.data as Uint8Array);

      const newData = new Uint8Array(await response.arrayBuffer());

      if (!oldData) {
        logger.log('No resource state stored, using fresh remote value.');
        return newData;
      }

      try {
        logger.log('Merging remote state with locally stored one.');
        const mergedData = Y.mergeUpdates([oldData, newData]);

        const diff = Y.diffUpdate(oldData, newData);
        if (diff.length > 0 && fdoc) {
          logger.log('Diff detected between local<>remote state, sending diff to remote.');
          mutationUpdateNotepadState.mutate({
            update: diff,
            resource: fdoc,
          });
        }

        return mergedData;
      } catch (e) {
        captureException(e);
        logger.error('Unable to merge YJS data, discarding local cached version.');

        return newData;
      }
    },
    staleTime: 0,
    refetchInterval: false,
    retry: false,
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    ...queryOptions,
    enabled: isQueryEnabled([!!fdoc?.id, queryOptions?.enabled]),
  });

  return {
    ...query,
  };
};
