import { useCallback } from 'react';
import { useAuthUser } from '../hooks/auth';
import { useWoody } from '../services/woody/woody';
import { MAX_UPLOAD_SIZE_FREE } from '../store/fileUploadStore';
import { isSubscribedPlan } from '../types/pricing';
import { FileUploadError, FileUploadErrorCodes } from './bulkUploader/uploader';

const useUploadNotepadImage = (fdocId?: string, createFdoc?: () => Promise<string | undefined>) => {
  const { client } = useWoody();
  const user = useAuthUser();

  const upload = useCallback(
    async (file: File) => {
      let id = fdocId;
      if (createFdoc) {
        id = await createFdoc();
        console.log('Created new fdoc', id);
      }

      if (!user || !id) {
        throw new FileUploadError(
          FileUploadErrorCodes.Unknown,
          'Upload function not ready. Please try again.',
          true,
        );
      }

      if (file.size > MAX_UPLOAD_SIZE_FREE && !isSubscribedPlan(user.subscription.tier))
        throw new FileUploadError(FileUploadErrorCodes.FileTooLarge, 'This file is too big!');

      const res = await client.getPresignedPostForNotepad(id, file.name);
      if (res.status !== 200) {
        throw new FileUploadError(FileUploadErrorCodes.FileUploadFailed, 'Upload failed.', true);
      }

      const { presigned, location } = res.data;

      await client.uploadFileR2(presigned, file);

      return location;
    },
    [client, user, fdocId, createFdoc],
  );

  if (!user) {
    return null;
  }

  return upload;
};

/**
 * Given an image URL will test if it's a valid image that would work inside a <img> tag.
 * - If the URL is valid, returns the URL.
 * - If the URL is invalid, returns null.
 * - If the URL is valid but not an image, returns null.
 */
export const validateImageUrl = async (url?: string) => {
  if (!url) {
    return null;
  }

  try {
    const res = await fetch(url, { method: 'HEAD' });
    if (res.status !== 200) {
      return null;
    }

    // Check if the content type is an image
    const contentType = res.headers.get('content-type');
    if (!contentType || !contentType.startsWith('image/')) {
      return null;
    }

    // Check if the image is valid by loading it into an image element
    return new Promise<string | null>((resolve) => {
      const img = new Image();
      img.onload = () => resolve(url);
      img.onerror = () => resolve(null);
      img.src = url;
    });
  } catch (e) {
    return null;
  }
};

export default useUploadNotepadImage;
