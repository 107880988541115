import { useExpandedFdocContext } from '@/src/components/ExpandedFdoc/ExpandedFdocProvider';
import useDebounce from '@/src/hooks/debounce';
import { useResponsive } from '@/src/hooks/responsive';
import useDownloadFdoc from '@/src/hooks/useDownloadFdoc';
import { useUnmount } from '@/src/hooks/useUnmount';
import { useQueryResourceFileLikeDetails } from '@/src/modules/resource-detail/queries/useQueryResourceFileLikeDetails';
import { ButtonIconDownload } from '@/src/modules/ui/components/button/ButtonIconDownload';
import { PinchZoomImage } from '@/src/modules/ui/components/images/PinchZoomImage';
import { useSharedStateStore } from '@/src/store/sharedState';
import { StoredFileFdoc } from '@/src/types/api';
import { AnimatePresence, motion } from 'framer-motion';
import React, { CSSProperties, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { shallow } from 'zustand/shallow';
import FileDisplay from '../FileDisplay/FileDisplay';
import Spinner from '../Spinner/Spinner';
import styles from './ExpandedFdoc.module.scss';
import { useExpandedFdocContentContext } from './ExpandedFdocContent';
import StoredPDFContent from './ExpandedPDF/StoredPDFContent';

type StoreFileContentProps = {
  fdoc: StoredFileFdoc;
  imageRef: React.RefObject<HTMLImageElement>;
  isFullscreenEnabled: boolean;
  tinyPagesIsVisible: boolean;
  disableGestures?: boolean;
};

export function StoredFileContent({
  fdoc,
  imageRef,
  isFullscreenEnabled,
  tinyPagesIsVisible,
  disableGestures,
}: StoreFileContentProps) {
  const { data: fileLikeDetails, refetch } = useQueryResourceFileLikeDetails(fdoc);
  const { setToolbarHeight } = useExpandedFdocContext();
  const { topHeaderControlsRef } = useExpandedFdocContentContext();
  const [mediaElement, setMediaElement] = useState<HTMLVideoElement | HTMLAudioElement | null>(
    null,
  );

  const { isMobileView } = useResponsive();

  const { mediaPlaying, mediaTimestamp, setMediaPlaying, setMediaTimestamp } = useSharedStateStore(
    (state) => ({
      mediaPlaying: state.mediaPlaying,
      mediaTimestamp: state.mediaTimestamp,
      setMediaPlaying: state.setMediaPlaying,
      setMediaTimestamp: state.setMediaTimestamp,
    }),
    shallow,
  );

  const debouncedMediaPlaying = useDebounce(mediaPlaying, 300);
  const [localMediaPlaying, setLocalMediaPlaying] = useState(false);
  const debouncedLocalMediaPlaying = useDebounce(localMediaPlaying, 300);

  const onPlay = (e: React.SyntheticEvent<HTMLVideoElement | HTMLAudioElement>) => {
    if (e.currentTarget !== mediaElement) return;
    setMediaPlaying(true);
    setLocalMediaPlaying(true);
    setMediaTimestamp(e.currentTarget.currentTime);
  };

  const onPause = (e: React.SyntheticEvent<HTMLVideoElement | HTMLAudioElement>) => {
    if (e.currentTarget !== mediaElement) return;
    setMediaPlaying(false);
    setLocalMediaPlaying(false);
    setMediaTimestamp(e.currentTarget.currentTime);
  };

  const onSeeked = (e: React.SyntheticEvent<HTMLVideoElement | HTMLAudioElement>) => {
    if (e.currentTarget !== mediaElement) return;
    setMediaTimestamp(e.currentTarget.currentTime);
  };

  const onTimeUpdate = (e: React.SyntheticEvent<HTMLVideoElement | HTMLAudioElement>) => {
    if (e.currentTarget !== mediaElement) return;
    setMediaTimestamp(e.currentTarget.currentTime);
  };

  useUnmount(() => {
    setMediaPlaying(false);
    setLocalMediaPlaying(false);
    setMediaTimestamp(null);
  });

  useEffect(() => {
    // if the mediaPlaying changes and it's not the same as what the current media is, then we pause/play the media
    if (debouncedLocalMediaPlaying !== debouncedMediaPlaying) {
      if (debouncedMediaPlaying) {
        mediaElement?.play();
      } else {
        mediaElement?.pause();
      }
    }
  }, [debouncedMediaPlaying, mediaElement, debouncedLocalMediaPlaying]);

  useEffect(() => {
    if (!mediaTimestamp) return;

    if (mediaElement && Math.abs(mediaElement.currentTime - mediaTimestamp) > 0.2) {
      mediaElement.currentTime = mediaTimestamp;
    }
  }, [mediaTimestamp, mediaElement]);

  const isPDF = fdoc?.data.contentType === 'application/pdf';
  const isImage = fdoc?.data.contentType?.startsWith('image');
  const isVideo = fdoc?.data.contentType?.startsWith('video');
  const isAudio = fdoc?.data.contentType?.startsWith('audio');

  const [videoLoaded, setVideoLoaded] = useState(false);

  React.useEffect(() => {
    if (isVideo && videoLoaded) {
      setToolbarHeight(30);
    } else {
      setToolbarHeight(0);
    }
  }, [isVideo, setToolbarHeight, videoLoaded]);

  const downloadFdoc = useDownloadFdoc();

  const handleDownloadFile = () => {
    downloadFdoc(fdoc);
  };
  const onVideoLoaded = (e: React.SyntheticEvent<HTMLVideoElement>) => {
    setVideoLoaded(true);

    // we set it to currenTime 0 to force  to show a thumbnail
    // otherwise it will show a blank screen until the video is played
    e.currentTarget.currentTime = 0;
  };

  useEffect(() => {
    if (!mediaElement?.readyState || mediaElement.readyState < 3) return;
    setVideoLoaded(true);
  }, [mediaElement?.readyState]);

  const downloadButton = topHeaderControlsRef
    ? createPortal(<ButtonIconDownload onClick={handleDownloadFile} />, topHeaderControlsRef)
    : null;

  if (isPDF) {
    return (
      <StoredPDFContent
        fdoc={fdoc}
        storedFile={fileLikeDetails}
        clearStoredFile={refetch}
        isFullScreen={isFullscreenEnabled}
        tinyPagesIsVisible={tinyPagesIsVisible}
      />
    );
  }

  if ((!isImage && !isVideo && !isAudio) || !fdoc.data.contentType) {
    return (
      <div className={styles.stored_file}>
        {downloadButton}
        <FileDisplay
          fileExtension={fdoc.data.extension}
          fileName={fdoc.data.title}
          onClick={handleDownloadFile}
        />
      </div>
    );
  }
  if (isImage) {
    // @TODO better provide image srcSet
    const imageSrc = isMobileView
      ? fileLikeDetails?.thumbnail?.lg ?? fileLikeDetails?.url
      : fileLikeDetails?.thumbnail?.xl ?? fileLikeDetails?.url;
    return (
      <>
        {downloadButton}
        {imageSrc && (
          <PinchZoomImage
            src={imageSrc}
            alt={fdoc.data.title}
            ref={imageRef}
            draggable={false}
            disableGestures={disableGestures}
          />
        )}
      </>
    );
  }

  if (isVideo) {
    const thumbnailAsBackground: CSSProperties =
      (fdoc.data.thumbnail && {
        backgroundImage: `url(${fdoc.data.thumbnail.md})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
      }) ||
      {};
    return (
      <div className={styles.item_preview__content__body__video} data-testid="item-content-section">
        {downloadButton}
        {fdoc.data.url !== 'load' && fileLikeDetails?.url && (
          <video
            preload="metadata"
            onLoadedMetadata={onVideoLoaded}
            src={fileLikeDetails.url}
            controls
            draggable={false}
            // Media controls
            onPlay={onPlay}
            onPause={onPause}
            onSeeked={onSeeked}
            onTimeUpdate={onTimeUpdate}
            ref={setMediaElement}
          />
        )}
        <AnimatePresence>
          {!videoLoaded && (
            <motion.div
              key="placeholder"
              className={styles.video_placeholder}
              style={thumbnailAsBackground}
              initial={{
                opacity: 1,
              }}
              exit={{
                opacity: 0,
                transition: {
                  duration: 0.2,
                },
              }}
            >
              <Spinner opacity={0.8} size={32} color="#808080" />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    );
  }

  if (isAudio)
    return (
      <div
        className={styles.item_preview__content__body__audio}
        data-testid="item-content-section"
        data-or-obscured
      >
        {downloadButton}
        {fdoc.data.url === 'load' || !fileLikeDetails?.url ? (
          <div className={styles.audio_placeholder}>
            <Spinner opacity={0.1} size={32} />
          </div>
        ) : (
          <audio
            ref={setMediaElement}
            src={fileLikeDetails.url}
            controls
            // Media controls
            onPlay={onPlay}
            onPause={onPause}
            onSeeked={onSeeked}
            onTimeUpdate={onTimeUpdate}
            draggable={false}
          />
        )}
      </div>
    );

  return null;
}
