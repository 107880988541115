import { IconBookmark } from '@/src/modules/icons';
import { ColorLabel } from '@/src/modules/resources/components/ResourcePreview/components/ColorLabel';
import { ResourcePreviewFavicon } from '@/src/modules/resources/components/ResourcePreview/components/ResourcePreviewFavicon';
import {
  ResourcePreviewDescription,
  ResourcePreviewTitle,
} from '@/src/modules/resources/components/ResourcePreview/components/Typography';
import { ResourcePreviewContentProps } from '@/src/modules/resources/components/ResourcePreview/ResourcePreview.types';
import { ResourcePreviewLoadingStateGrid } from '@/src/modules/resources/components/ResourcePreview/ResourcePreviewGrid/components/LoadingState';
import { containerPreviewCompact } from '@/src/modules/resources/components/ResourcePreview/ResourcePreviewGrid/cssPreviewContainer';
import { cssAspectRatio } from '@/src/modules/resources/components/ResourcePreview/ResourcePreviewGrid/sharedCss';
import { Flex } from '@/src/modules/ui/components/Flex';
import { Image } from '@/src/modules/ui/components/Image';
import { cssLineClamp } from '@/src/modules/ui/styled-utils';
import { cssVar } from '@/src/modules/ui/theme/variables';
import { PageFdoc } from '@/src/types/api';
import { OptimisticDraft } from '@/src/types/draftable';
import { cleanUrl } from '@/src/utils/text';
import { SPOTIFY_URL_REGEX, TWITTER_URL_REGEX } from '@/src/utils/url-regex';
import React from 'react';
import styled from 'styled-components';

interface ResourcePreviewPageProps {
  resource: OptimisticDraft<PageFdoc>;
  colorLabels?: ResourcePreviewContentProps['colorLabels'];
}

export const ResourcePreviewPage: React.FC<ResourcePreviewPageProps> = ({
  resource,
  colorLabels,
}) => {
  const coverSrc = resource.thumbnail?.sm || resource.data.webpage?.image?.url;
  const pageDescription = resource.data.webpage?.description;

  /**
   * we might rather split into N components in the future than having conditions everywhere
   */
  const isTwitter = resource.data.pageUrl.match(TWITTER_URL_REGEX);
  const twitterHandle = isTwitter
    ? new URL(resource.data.pageUrl).pathname.split('/')[1]
    : undefined;

  const isSpotify = resource.data.pageUrl.match(SPOTIFY_URL_REGEX);
  const spotifyArtist =
    resource.data.webpage?.metadata &&
    isSpotify &&
    'artist' in resource.data.webpage?.metadata &&
    (resource.data.webpage?.metadata.artist as string);

  return (
    <Wrapper data-preview-content-wrapper="page">
      <ResourcePreviewFavicon pageUrl={resource.data.pageUrl} position="absolute-top-left" />
      {/** image  */}
      <ThumbnailWrapper>
        <ColorLabel absPosition="inside-top-left" colorLabels={colorLabels} resource={resource} />
        <ResourcePreviewLoadingStateGrid resource={resource} />
        {coverSrc ? (
          <ThumbnailImage src={coverSrc} alt={resource.data.pageTitle} draggable={false} />
        ) : (
          <EmptyImageState>
            <IconBookmark style={{ width: '4.25rem', height: '4.25rem' }} />
          </EmptyImageState>
        )}
      </ThumbnailWrapper>
      <PageInfo>
        <ResourcePreviewTitle>
          {twitterHandle && <TwitterHandle>@{twitterHandle} </TwitterHandle>}
          {resource.data.pageTitle}
        </ResourcePreviewTitle>
        {!isTwitter && (
          <ResourcePreviewDescription>
            {spotifyArtist || cleanUrl(resource.data.pageUrl)}
          </ResourcePreviewDescription>
        )}
        {resource.data.pageUrl.match(TWITTER_URL_REGEX) && pageDescription && (
          <PageDescription>{pageDescription}</PageDescription>
        )}
      </PageInfo>
    </Wrapper>
  );
};

const PageInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.3125rem;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 0.625rem;
  overflow: hidden;
  justify-content: center;
  padding: 0.75rem;
  border: 1px solid var(--preview-border-color, ${cssVar['color-border-secondary']});
  background: var(--preview-background-color, ${cssVar['color-bg-primary']});
  border-radius: 1.125rem;
  position: relative;
  ${cssAspectRatio};

  ${containerPreviewCompact} {
    border: none;
    border-radius: 0;
    padding: 0.6875rem;
  }
`;

/** thumbnail or empty state */

const ThumbnailWrapper = styled(Flex).attrs({
  direction: 'column',
  justifyContent: 'center',
  alignItems: 'center',
})`
  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden;
`;

const ThumbnailImage = styled(Image)`
  max-width: 100%;
  min-height: 1px;
  object-fit: scale-down;
  /* height: 100%;
  width: 100%;
  object-fit: cover; */
  border: 1px solid ${cssVar['color-border-secondary']};
  display: block;
  border-radius: 0.5rem;
  background: ${cssVar['color-bg-section']};
`;

const EmptyImageState = styled.div`
  height: 100;
  flex-grow: 1;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  background: ${cssVar['color-bg-section']};
  color: ${cssVar['color-bg-primary']};
`;

const PageDescription = styled.p`
  color: ${cssVar['color-text-quaternary']};
  font-size: 0.8125rem;
  line-height: 1.25rem;
  ${cssLineClamp(3)}
  ${containerPreviewCompact} {
    font-size: 0.75rem;
    line-height: 1rem;
    ${cssLineClamp(2)}
  }
`;

const TwitterHandle = styled.span`
  color: ${cssVar['color-text-quaternary']};
  opacity: 0.8;
`;
