import { withConfirmation } from '@/src/lib/react-query/utilities';
import { AnalyticsEvents } from '@/src/modules/analytics/analytics.types';
import { useAnalytics } from '@/src/modules/analytics/hooks/useAnalytics';
import { resourceQueryPredicates } from '@/src/modules/resources/queries/resourceQueryPredicates';
import { useQueryCacheResourceHelpers } from '@/src/modules/resources/utils/useQueryCacheResourceHelpers';
import { useWoody } from '@/src/services/woody/woody';
import { toast } from '@/src/store/alerts';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import plur from 'plur';

export const useMutationDeleteResourcesById = () => {
  const { client } = useWoody();
  const queryClient = useQueryClient();
  const { track } = useAnalytics();

  const { deleteCachedResources } = useQueryCacheResourceHelpers();

  const mutation = useMutation({
    mutationFn: async (resourceIds: string[]) => {
      await client.v2('/v2/resource/delete', {
        method: 'post',
        body: {
          resourceIds,
        },
      });

      return {
        resourceIds,
      };
    },
    onMutate: (resourceIds) => {
      return deleteCachedResources(resourceIds);
    },
    onSuccess: (res, _, ctx) => {
      const deletedItemsTotal = res?.resourceIds.length || 0;
      toast({
        content: deletedItemsTotal > 1 ? `${deletedItemsTotal} items deleted` : 'Item deleted',
      });

      track(AnalyticsEvents.DeletedResource, { count: deletedItemsTotal });

      queryClient.invalidateQueries({ predicate: resourceQueryPredicates.summaryAll });

      // invalidate only direct queries
      ctx?.optimisticallyUpdateDirectQueries.invalidateQueries();

      window.dispatchEvent(
        new CustomEvent('fabric-resources-deleted', {
          detail: {
            quantity: deletedItemsTotal,
          },
        }),
      );
    },
    onError: (_, _r, context) => {
      toast({
        content: 'Failed to delete',
      });

      context?.resetCacheToPreOptimisticState();
      context?.invalidateQueries();
    },
  });

  return withConfirmation(mutation, (resourceIds: string[]) => ({
    title: `Delete ${plur('item', resourceIds.length)} - Are you sure?`,
    content: `Are you sure you want to delete ${plur('this', 'these', resourceIds.length)} ${plur('item', resourceIds.length)}? This action cannot be undone.`,
    confirmLabel: 'Yes, Delete',
    cancelLabel: 'Cancel',
    confirmButtonProps: {
      variant: 'danger',
    },
  }));
};
