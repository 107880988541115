import { IconBookmark } from '@/src/modules/icons';
import { useQueryPageFavicon } from '@/src/modules/resource-detail/queries/useQueryPageFavicon';
import { ColorLabel } from '@/src/modules/resources/components/ResourcePreview/components/ColorLabel';
import { ResourcePreviewFavicon } from '@/src/modules/resources/components/ResourcePreview/components/ResourcePreviewFavicon';
import {
  ResourcePreviewDescription,
  ResourcePreviewTitle,
} from '@/src/modules/resources/components/ResourcePreview/components/Typography';
import { ResourcePreviewContentProps } from '@/src/modules/resources/components/ResourcePreview/ResourcePreview.types';
import { ResourcePreviewLoadingStateGrid } from '@/src/modules/resources/components/ResourcePreview/ResourcePreviewGrid/components/LoadingState';
import { containerPreviewCompact } from '@/src/modules/resources/components/ResourcePreview/ResourcePreviewGrid/cssPreviewContainer';
import { cssAspectRatio } from '@/src/modules/resources/components/ResourcePreview/ResourcePreviewGrid/sharedCss';
import { Image } from '@/src/modules/ui/components/Image';
import { cssVar } from '@/src/modules/ui/theme/variables';
import { ImageFdoc } from '@/src/types/api';
import { OptimisticDraft } from '@/src/types/draftable';
import { cleanUrl } from '@/src/utils/text';
import styled from 'styled-components';

interface ResourcePreviewImageProps {
  resource: OptimisticDraft<ImageFdoc>;
  colorLabels?: ResourcePreviewContentProps['colorLabels'];
}

export const ResourcePreviewImage: React.FC<ResourcePreviewImageProps> = ({
  resource,
  colorLabels,
}) => {
  const { data: faviconUrl } = useQueryPageFavicon(resource.data.pageUrl);
  return (
    <Wrapper data-preview-content-wrapper>
      <ColorLabel absPosition="inside-top-left" colorLabels={colorLabels} resource={resource} />
      <ResourcePreviewLoadingStateGrid resource={resource} />
      {faviconUrl && (
        <ResourcePreviewFavicon pageUrl={resource.data.pageUrl} position="absolute-top-left" />
      )}
      {/** image  */}
      {resource.thumbnail?.sm ? (
        <ThumbnailImage
          src={resource.thumbnail?.sm}
          alt={resource.data.pageTitle}
          draggable={false}
        />
      ) : (
        <EmptyImageState>
          <IconBookmark style={{ width: '4.25rem', height: '4.25rem' }} />
        </EmptyImageState>
      )}
      <PageInfo>
        <ResourcePreviewTitle>{resource.data.pageTitle}</ResourcePreviewTitle>
        <ResourcePreviewDescription>{cleanUrl(resource.data.pageUrl)}</ResourcePreviewDescription>
      </PageInfo>
    </Wrapper>
  );
};

const PageInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.3125rem;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 0.625rem;
  overflow: hidden;
  justify-content: center;
  padding: 0.75rem;
  border: 1px solid ${cssVar['color-border-secondary']};
  background: ${cssVar['color-bg-primary']};
  border-radius: 1.125rem;
  position: relative;
  ${cssAspectRatio};

  ${containerPreviewCompact} {
    border: none;
    border-radius: 0;
    padding: 0.6875rem;
  }
`;

/** thumbnail or empty state */

const ThumbnailImage = styled(Image)`
  width: 100%;
  min-height: 1px;
  flex-grow: 1;
  object-fit: cover;
  display: block;
  border-radius: 0.5rem;
  background: ${cssVar['color-bg-section']};
`;

const EmptyImageState = styled.div`
  height: 100;
  flex-grow: 1;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  background: ${cssVar['color-bg-section']};
  color: ${cssVar['color-bg-primary']};
`;
