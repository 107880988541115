import World from '@/public/images/icons/World.svg';
import { useQueryPageResourcePreview } from '@/src/modules/resource-detail/queries/useQueryPageResourcePreview';
import StateSpinner from '@/src/modules/resources/components/StateSpinner';
import { isResourceStateProcessing } from '@/src/modules/resources/utils/isResourceStateProcessing';
import { Flex } from '@/src/modules/ui/components/Flex';
import { Image } from '@/src/modules/ui/components/Image';
import { P, TypographyContainer } from '@/src/modules/ui/components/Typography';
import { cssVar } from '@/src/modules/ui/theme/variables';
import { PageFdoc } from '@/src/types/api';
import dayjs from 'dayjs';
import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { Void } from '../../ui/components/Void';
import { pageResourceEmbeds } from '../expandedResource.config';

type PageResourceMetadataProps = {
  resource: PageFdoc;
};

const MetadataImageContainer = styled.div`
  border: 1px solid ${cssVar['color-border-primary']};
  background-color: ${cssVar['color-bg-primary']};
  border-radius: 14px;
  overflow: hidden;
  padding: 10px;
`;

const MetadataImageEmptyContainer = styled(Flex).attrs({
  direction: 'column',
  gap: 'elementsContainer',
  alignItems: 'center',
  justifyContent: 'center',
  flexGrow: 1,
})`
  padding: 1rem;
  background-color: ${cssVar['color-bg-quarternary']};
  border-radius: 6px;
  max-height: 300px;
`;

const MetadataDescription = styled(Flex).attrs({
  direction: 'row',
  gap: 'elementsContainer',
  alignItems: 'center',
})`
  padding: 1rem;
  background-color: ${cssVar['color-bg-primary']};
  border-radius: 6px;
`;

const Container = styled(Flex).attrs({
  direction: 'column',
  gap: 'elementsContainer',
  flexGrow: 1,
})`
  width: 100%;
  max-width: 520px;
`;

export const PageResourceMetadata = forwardRef<HTMLDivElement, PageResourceMetadataProps>(
  ({ resource }, ref) => {
    /**
     * At the moment there is no easy way to know if a preview is still loading or non-existent.
     * So we are assuming if a resource is fresh the preview is most likely loading.
     * Fresh resources are (each condition is an OR):
     * - Not older than 5 minutes
     * - `fdoc.data.statePreview` is not `processing`
     * - fdoc is not still indexing
     */
    const isFreshResource =
      dayjs().diff(dayjs(resource.createdAt), 'minute') < 5 ||
      isResourceStateProcessing(resource.data.statePreview) ||
      isResourceStateProcessing(resource.stateProcessing);

    const {
      data: preview,
      isLoading: previewLoading,
      error,
    } = useQueryPageResourcePreview(
      resource,
      isFreshResource
        ? {
            refetchInterval: (query) => {
              return query.state.error ? query.state.fetchFailureCount * 1000 * 15 : false;
            },
            refetchOnMount: (query) => (Boolean(query.state.error) ? 'always' : true),
            staleTime: 0,
          }
        : undefined,
    );

    const [imageFailed, setImageFailed] = React.useState(false);

    const embed = pageResourceEmbeds.find((embed) => embed.matchFn(resource.data.pageUrl));

    const EmbedElement = embed?.renderElement ?? Void;

    const showImage = Boolean(!imageFailed && preview?.image?.url);
    const showDescription = Boolean(preview?.title || preview?.description);

    if (previewLoading || (isFreshResource && error))
      return (
        <Container ref={ref}>
          <MetadataImageEmptyContainer>
            <StateSpinner size={22} thickness={3} />
            <P color="tertiary" size="sm">
              Fetching website image
            </P>
          </MetadataImageEmptyContainer>
          <MetadataDescription>
            <StateSpinner size={22} thickness={3} />
            <P color="tertiary" size="sm">
              Fetching website metadata
            </P>
          </MetadataDescription>
        </Container>
      );

    return (
      <Container ref={ref}>
        <EmbedElement url={resource.data.pageUrl}>
          {showImage && (
            <MetadataImageContainer>
              <Image
                src={preview?.image?.url ?? undefined}
                alt="Webpage preview"
                onError={() => setImageFailed(true)}
                fullWidth
              />
            </MetadataImageContainer>
          )}

          {!showImage && !showDescription && (
            <Flex
              direction="column"
              gap="elementsContainer"
              alignItems="center"
              justifyContent="center"
              flexGrow
            >
              <P color="tertiary">
                <World
                  style={{
                    width: 64,
                    height: 64,
                  }}
                />
              </P>
              <P color="tertiary" size="sm">
                No link preview
              </P>
            </Flex>
          )}
        </EmbedElement>
        {showDescription && (
          <MetadataDescription>
            <TypographyContainer>
              {preview?.title && (
                <P color="tertiary" size="sm" weight={500}>
                  {preview.title}
                </P>
              )}
              {preview?.description && (
                <P color="tertiary" size="sm" wordBreak="break-word">
                  {preview.description}
                </P>
              )}
            </TypographyContainer>
          </MetadataDescription>
        )}
      </Container>
    );
  },
);

PageResourceMetadata.displayName = 'PageResourceMetadata';
