import LinkIcon from '@/public/images/icons/Link.svg';
import React, { SyntheticEvent } from 'react';

import { useAuthUser } from '@/src/hooks/auth';
import useCopyToClipboard from '@/src/hooks/useCopyToClipboard';
import { AnalyticsEvents } from '@/src/modules/analytics/analytics.types';
import { useAnalytics } from '@/src/modules/analytics/hooks/useAnalytics';
import { useQueryMembersByListId } from '@/src/modules/members/queries/useQueryMembersByListId';
import { useMutationChangeResourceShare } from '@/src/modules/resource-detail/mutations/useMutationChangeResourceShare';
import { useQueryResourceRoot } from '@/src/modules/resource-roots/queries/useQueryResourceRoot';
import { useQueryResourceDetail } from '@/src/modules/resources/queries/useQueryResourceDetail';
import { useMutationChangeSpaceShared } from '@/src/modules/spaces/mutations/useMutationChangeSpaceShared';
import { AnimateChangeInHeight } from '@/src/modules/ui/components/animations/AnimateChangeInHeight';
import Divider from '@/src/modules/ui/components/Divider';
import { Flex } from '@/src/modules/ui/components/Flex';
import { Kbd } from '@/src/modules/ui/components/Kbd';
import Modal from '@/src/modules/ui/components/Modal';
import { Spinner } from '@/src/modules/ui/components/Spinner';
import { P, TypographyContainer } from '@/src/modules/ui/components/Typography';
import { framerAnimationFade } from '@/src/modules/ui/constants/framerAnimations';
import AvatarUser from '@/src/modules/user/components/AvatarUser/AvatarUser';
import { toast } from '@/src/store/alerts';
import { VisuallyHidden } from '@radix-ui/react-visually-hidden';
import { useMutation } from '@tanstack/react-query';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import Toggle from '../../../ui/Toggle/Toggle';

const MemberList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const ManualInputCopy = styled.input`
  width: 100%;
  border: none;
  background-color: #f5f5f5;
  padding: 12px;
  margin-top: 6px;
  outline: none;
`;

/**
 * kinda mix of space vs resource sharing, could use a refactor but we're moving forward new tiered sharing anyway
 */
const ShareModal: React.FC<{
  resourceId?: string;
  spaceId?: string;
  onClose: (e?: SyntheticEvent) => void;
  getLink?: () => Promise<string | undefined>;
  sharingObject?: 'space' | 'item';
}> = ({ resourceId, spaceId, onClose, sharingObject = 'space', getLink: getLinkProp }) => {
  const user = useAuthUser();
  const { resourceRoot, isLoading: isRootLoading } = useQueryResourceRoot(spaceId);
  const { fdoc: resource, isLoading: isResourceLoading } = useQueryResourceDetail(resourceId, {
    enabled: !!resourceId,
  });

  const { members, isLoading: isSpaceMembersLoading } = useQueryMembersByListId(spaceId);

  const isLoading = isSpaceMembersLoading || isResourceLoading || isRootLoading;

  const isPublic = resource?.isDirectShared ?? resourceRoot?.folder.isPublic ?? false;

  const mutationChangeResourceShare = useMutationChangeResourceShare();
  const mutationChangeSpaceShare = useMutationChangeSpaceShared();

  const getLink = async () => {
    if (getLinkProp) return getLinkProp();
    if (resourceRoot) return `${window.location.origin}/s/${resourceRoot.folder.id}`;
    if (!resource) return;

    const response = await mutationChangeResourceShare.mutateAsync({
      resource,
      shared: true,
    });

    return response!;
  };

  const [, copyToClipboard] = useCopyToClipboard();

  const isSpaceOwner = resourceRoot ? resourceRoot?.folder.permissions.role === 'owner' : null;
  const isResourceOwner = !!resource?.user?.id && resource?.user?.id === user?.id;
  const canToggleSharing = isResourceOwner || isSpaceOwner;

  const instantToggle = () => {
    /**
     * ideally make sure to guard UI
     */
    if (!canToggleSharing) {
      toast({
        content: 'Only the owner can change sharing settings',
        id: 'cannot-share-toast',
      });
      return;
    }

    if (resourceRoot && isSpaceOwner)
      mutationChangeSpaceShare.mutate({
        space: {
          id: resourceRoot.id,
          title: resourceRoot.folder.name || '',
          isPublic: resourceRoot.folder.isPublic,
        },
        shared: !isPublic,
      });

    if (resource && isResourceOwner)
      mutationChangeResourceShare.mutate({
        resource,
        shared: !isPublic,
      });
  };

  const { track } = useAnalytics();

  const mutationCopyLink = useMutation<boolean, { message: string; url?: string | null }>({
    mutationFn: async () => {
      const url = await getLink();
      if (!url) {
        throw { message: 'Failed to get link' };
      }
      const copyResult = await copyToClipboard(url);
      if (!copyResult) {
        throw { message: 'Failed to copy link', url };
      } else {
        return true;
      }
    },
    onError: () => {
      toast({
        content: 'An error occurred while sharing the link, please copy from the input below',
      });
    },
    onSuccess: async () => {
      track(AnalyticsEvents.CopiedShareLink, {
        collectionId: resourceRoot?.id,
        resourceId: resource?.id,
      });

      setTimeout(mutationCopyLink.reset, 3000);
    },
  });

  const showMembers = members && members?.length > 1;

  return (
    <Modal
      open
      onOpenChange={(value) => {
        if (!value) {
          onClose();
        }
      }}
    >
      <Modal.Portal>
        <Modal.Overlay visibleOnMobileViewport />
        <Modal.Content width="sm" disableMobileFullscreen>
          <VisuallyHidden>
            <Modal.Title size="md-shared">Sharing</Modal.Title>
            <Modal.Description>Enable sharing with others</Modal.Description>
          </VisuallyHidden>
          <AnimateChangeInHeight>
            {isLoading ? (
              <Modal.Body
                as={motion.div}
                {...framerAnimationFade}
                style={{
                  padding: '5rem',
                }}
              >
                <Flex alignItems="center" justifyContent="center">
                  <Spinner size={24} />
                </Flex>
              </Modal.Body>
            ) : (
              <Modal.Body style={{ gap: '1.5rem' }} as={motion.div} {...framerAnimationFade}>
                <Flex direction="column" gap="elementsContainer">
                  <Modal.Title size="md-shared">Sharing</Modal.Title>
                  {/** content */}
                  <Flex direction="column" gap="sectionsShort">
                    {canToggleSharing && (
                      <>
                        <Flex justifyContent="space-between" alignItems="center">
                          <P weight={500}>Share with link</P>
                          <Toggle
                            label=""
                            checked={isPublic}
                            onChange={instantToggle}
                            disabled={
                              mutationChangeResourceShare.isPending ||
                              mutationChangeSpaceShare.isPending
                            }
                          />
                        </Flex>
                        <TypographyContainer>
                          <P size="md" weight={500} color="quaternary">
                            {sharingObject === 'space'
                              ? 'Anyone with the link can edit and comment on items in the space.'
                              : 'Anyone with the link can edit and comment on this item.'}
                            <br />
                            <br />
                            No need to make an account to view.
                          </P>
                        </TypographyContainer>
                      </>
                    )}
                    {canToggleSharing && showMembers && <Divider borderColor="secondary" />}
                    {showMembers && (
                      <MemberList>
                        {members.map((member) => (
                          <Flex key={member.id} alignItems="center" justifyContent="space-between">
                            <Flex gap={12} alignItems="center">
                              <AvatarUser size={24} user={member} />
                              <P size="md" weight={500}>
                                {member.name}
                              </P>
                            </Flex>
                            {/* TODO: Return this to be the role given by the backend when we implement full roles */}
                            <P
                              size="md"
                              color="quaternary"
                              style={{
                                textTransform: 'capitalize',
                              }}
                            >
                              {member.role !== 'owner' ? 'Collaborator' : member.role}
                            </P>
                          </Flex>
                        ))}
                      </MemberList>
                    )}
                    {!canToggleSharing && (!showMembers || members.length === 0) && (
                      <P>Only resource owner can update sharing settings.</P>
                    )}
                  </Flex>
                </Flex>
                <Modal.DoubleButtonContainer>
                  <Modal.CancelButton onClick={onClose} variant="bg-secondary">
                    Close
                    <Kbd>Esc</Kbd>
                  </Modal.CancelButton>
                  <Modal.Button
                    disabled={!isPublic}
                    onClick={() => {
                      mutationCopyLink.mutate();
                    }}
                    data-testid="copy-link-button"
                  >
                    <LinkIcon style={{ height: 16, width: 16 }} />
                    {mutationCopyLink.isSuccess ? 'Copied!' : 'Copy link'}
                  </Modal.Button>
                </Modal.DoubleButtonContainer>
                {mutationCopyLink.error &&
                  'url' in mutationCopyLink.error &&
                  !!mutationCopyLink.error.url && (
                    <ManualInputCopy
                      type="text"
                      value={mutationCopyLink.error.url}
                      readOnly
                      autoFocus
                      onFocus={(e) => e.target.select()}
                      onClick={(e) => {
                        e.currentTarget.select();
                        e.preventDefault();
                      }}
                    />
                  )}
              </Modal.Body>
            )}
          </AnimateChangeInHeight>
        </Modal.Content>
      </Modal.Portal>
    </Modal>
  );
};

export default ShareModal;
