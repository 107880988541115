import { ApiUserMe } from '@/src/modules/user/user.types';
import { OptimisticDraft } from '@/src/types/draftable';
import { v4 as uuidv4 } from 'uuid';
import { Comment } from '../comments.types';

export const createOptimisticComment = (
  user: ApiUserMe,
  content: string,
  resourceId: string,
): OptimisticDraft<Comment> => {
  const optimisticId = uuidv4();

  return {
    id: optimisticId,
    isDraft: true,
    content,
    draftId: optimisticId,
    createdAt: new Date().toISOString(),
    modifiedAt: new Date().toISOString(),
    user,
    resourceId,
    annotation: null,
  };
};
