import { SuggestionItem } from '@/src/modules/magic/magic.types';
import { OptimisticDraft } from '@/src/types/draftable';
import { PrivateTag } from '@fabric/woody-client';
import React from 'react';

interface NewResourceContextProps {
  selectedTags: OptimisticDraft<PrivateTag>[];
  onSelectTag: (tag: PrivateTag, selected: boolean) => void;
  clearSelectedTags: () => void;

  /**
   * destination
   */
  destinationParent:
    | {
        id: string;
        name: string;
      }
    | undefined;
  handleOpenDestinationFolder: () => void;

  /**
   * controls
   */
  handleClose: () => void;

  /**
   * 'Magic' suggestions
   */
  onSelectSuggestion: (suggestion: SuggestionItem) => void;
}
export const NewResourceContext = React.createContext<NewResourceContextProps>(
  {} as NewResourceContextProps,
);

export const useNewResourceContext = () => React.useContext(NewResourceContext);
