import CloudTickIcon from '@/public/images/icons/CloudTick.svg';
import UpdateIcon from '@/public/images/icons/Update.svg';
import DropdownMenu_Deprecated from '@/src/components/DropdownMenu/DropdownMenu';
import { FabricDataValue } from '@/src/components/Tiptap/slate.types';
import useAuthStore, { useAuthIsLoggedIn } from '@/src/hooks/auth';
import usePrevious from '@/src/hooks/previous';
import { useResponsive } from '@/src/hooks/responsive';
import { useDebouncedCallback } from '@/src/hooks/useDebouncedCallback';
import useDownloadFdoc from '@/src/hooks/useDownloadFdoc';
import { useInputControls } from '@/src/hooks/useInputControls';
import { useOnWindowClose } from '@/src/hooks/useOnWindowClose';
import { useReferencedFn } from '@/src/hooks/useReferencedFn';
import useSwipe from '@/src/hooks/useSwipe';
import { useThrottledCallback } from '@/src/hooks/useThrottledCallback';
import { useUnmount } from '@/src/hooks/useUnmount';
import ExitFullscreenIcon from '@/src/icons/ExitFullscreenIcon';
import FullscreenIcon from '@/src/icons/FullscreenIcon';
import MenuIcon from '@/src/icons/MenuIcon';
import useUploadNotepadImage from '@/src/lib/image';
import { pick } from '@/src/lib/store';
import { AnalyticsEvents } from '@/src/modules/analytics/analytics.types';
import { useAnalytics } from '@/src/modules/analytics/hooks/useAnalytics';
import { MagicSuggestions } from '@/src/modules/magic/components/MagicSuggestions';
import { MagicSuggestionsContextProvider } from '@/src/modules/magic/components/MagicSuggestionsContext';
import {
  SuggestionContext,
  SuggestionItem,
  SuggestionItemType,
} from '@/src/modules/magic/magic.types';
import { ResourceTitleEditable } from '@/src/modules/resource-detail/components/ResourceTitle/ResourceTitleEditable';
import { ResourceTitleUrl } from '@/src/modules/resource-detail/components/ResourceTitle/ResourceTitleUrl';
import { useContainedImageSize } from '@/src/modules/resource-detail/hooks/useContainedImageSize';
import {
  MIN_WIDTH,
  useResourceMoveAndResize,
} from '@/src/modules/resource-detail/hooks/useMoveAndResize';
import {
  useMutationNotepadStateUpdate,
  useOptimisticNotepadStateUpdate,
} from '@/src/modules/resource-detail/mutations/useMutationNotepadStateUpdate';
import { useMutationResourceTitleRename } from '@/src/modules/resource-detail/mutations/useMutationResourceTitleRename';
import { useMutationUpdateNotepadContent } from '@/src/modules/resource-detail/mutations/useMutationUpdateNotepadContent';
import { DestinationButton } from '@/src/modules/resources/components/NewResource/components/DestinationButton';
import { useNewResourceContext } from '@/src/modules/resources/components/NewResource/context/ModalNewResourceContext';
import StateSpinner from '@/src/modules/resources/components/StateSpinner';
import { useResourceStoredMetadata } from '@/src/modules/resources/hooks/useResourceStoredMetadata';
import { useMutationDeleteResourcesById } from '@/src/modules/resources/mutations/useMutationDeleteResourcesById';
import { useMutationMoveResourcesById } from '@/src/modules/resources/mutations/useMutationMoveResourcesById';
import { useQueryResourceDetail } from '@/src/modules/resources/queries/useQueryResourceDetail';
import {
  getResourceTitle,
  getResourceTitleWithDefault,
} from '@/src/modules/resources/utils/getResourceTitle';
import { isResourceStateProcessing } from '@/src/modules/resources/utils/isResourceStateProcessing';
import { isNotepadFdoc, isStoredFileFdoc } from '@/src/modules/resources/utils/resourceTypes';
import { useMutationResourcesTagAssign } from '@/src/modules/tags/mutations/useMutationResourcesTagAssign';
import { useMutationTagCreate } from '@/src/modules/tags/mutations/useMutationTagCreate';
import { useQueryResourceTags } from '@/src/modules/tags/queries/useQueryResourceTags';
import { ButtonIcon } from '@/src/modules/ui/components/Button';
import { Flex } from '@/src/modules/ui/components/Flex';
import Modal from '@/src/modules/ui/components/Modal';
import ShareModal from '@/src/modules/ui/components/ShareModal';
import { ButtonIconDotMenu } from '@/src/modules/ui/components/button/ButtonIconDotMenu';
import { ButtonIconDownload } from '@/src/modules/ui/components/button/ButtonIconDownload';
import { mediaMobile, mediaSidebarBottom } from '@/src/modules/ui/styled-utils';
import { cssVar } from '@/src/modules/ui/theme/variables';
import { useSpaceLiveblocksStore } from '@/src/multiplayer/spaces.config';
import useUIStore, { setGlobalSelectionOptions } from '@/src/store/ui';
import { Fdoc } from '@/src/types/api';
import { OptimisticDraft } from '@/src/types/draftable';
import Tooltip from '@/src/ui/Tooltip';
import { noteTextFormat, prettifyForLLM } from '@/src/utils/text';
import type { EditorJSData, PrivateTag } from '@fabric/woody-client';
import clsx from 'clsx';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import { shallow } from 'zustand/shallow';
import { PageResource } from '../../modules/expandedResource/components/PageResource';
import SpaceCursors from '../SpaceCursors/SpaceCursors';
import TagButton from '../Tags/Buttons/TagButton';
import EditTagsModal from '../Tags/EditTagsModal';
import MultiplayerEditor, { MultiplayerState } from '../Tiptap/MultiplayerEditor';
import { TiptapDataValue } from '../Tiptap/types';
import styles from './ExpandedFdoc.module.scss';
import { MediaContent } from './MediaContent';
import { StoredFileContent } from './StoredFileContent';

type ExpandedFdocContentProps = {
  fdoc: OptimisticDraft<Fdoc>;
  onClose?: () => void;
  sidebarRef: HTMLDivElement | null;
  rootRef: HTMLElement | null;
  setPendingChanges: (pendingChanges: boolean) => void;

  isEditorFocused: boolean;
  setIsEditorFocused: (isEditorFocused: boolean) => void;

  overrideSidebarOpen?: boolean;
  draftSaveFdoc?: (fdoc: OptimisticDraft<Fdoc>, state?: Uint8Array) => Promise<string | undefined>;
  draftOnSelectTag?: (tag: PrivateTag, selected: boolean) => void;
  draftTags?: PrivateTag[];
  isFullscreen: boolean;
  setIsFullscreen: React.Dispatch<React.SetStateAction<boolean>>;
  disableGestures?: boolean;
  hideBackdrop?: boolean;
};
interface IExpandedFdocContentContext {
  extraZoneRef: HTMLDivElement | null;
  topHeaderControlsRef: HTMLDivElement | null;
  dropdownMenuRef: HTMLUListElement | null;
  itemPreviewContentBodyRef: HTMLDivElement | null;
  isFullscreen: boolean;

  onClose?: () => void;
}

const StyledTagButton = styled(TagButton)`
  bottom: calc(var(--toolbar-height, 0px) + 1rem);
  display: none;

  ${mediaSidebarBottom} {
    bottom: calc(
      max(56px + env(safe-area-inset-bottom), var(--keyboard-height)) + var(--toolbar-height, 0px) +
        1rem
    );
    display: flex;
  }
`;

const DesktopTagButton = styled(TagButton)`
  position: static;
  box-shadow: none;
  background: ${cssVar['color-bg-secondary-button']};
  ${mediaSidebarBottom} {
    display: none;
  }
`;

const StyledSpinnerContainer = styled.div`
  padding-left: 15px;
  margin-right: -20px;

  ${mediaMobile} {
    padding-left: 8px;
    margin-right: -10px;
  }
`;

const ExpandedFdocContentContext = createContext<IExpandedFdocContentContext>({
  extraZoneRef: null,
  topHeaderControlsRef: null,
  dropdownMenuRef: null,
  itemPreviewContentBodyRef: null,
  isFullscreen: false,
  onClose: () => {},
});

export const useExpandedFdocContentContext = () => {
  return useContext(ExpandedFdocContentContext);
};

export type BodyContext = {
  size: { width: number; height: number } | null;
  scrollPosition: { x: number; y: number } | null;
  scrollHeight: number;
  scrollWidth: number;
};

const ExpandedFdocContent = React.forwardRef<HTMLDivElement, ExpandedFdocContentProps>(
  (
    {
      fdoc,
      onClose,
      sidebarRef,
      rootRef,
      isEditorFocused,
      setIsEditorFocused,
      draftSaveFdoc,
      draftOnSelectTag,
      draftTags,
      isFullscreen,
      setIsFullscreen,
      disableGestures,
      hideBackdrop,
    },
    ref,
  ) => {
    // Desktop/Web only state, mobile resoltions use the showCommentsMobile prop, because it doesn't get saved in the store
    const { expandedFdocSidebarOpen } = useUIStore(
      (s) => pick(s, ['expandedFdocSidebarOpen']),
      shallow,
    );

    const isProcessing = isResourceStateProcessing(fdoc?.stateProcessing) && !fdoc.isDraft;

    // cause the resource to be refetched on mount
    useQueryResourceDetail(fdoc.id, {
      refetchOnMount: 'always',
      enabled: !fdoc.isDraft,
    });

    const [notepadConnectionState, setNotepadConnectionState] =
      useState<MultiplayerState>('LOADING');

    const onMultiplayerStateChange = useReferencedFn((state: MultiplayerState) => {
      setNotepadConnectionState(state);
    });

    const onHasPendingChanges = useReferencedFn((hasPendingChanges: boolean) => {
      setNeedsSaving(hasPendingChanges);
    });

    const previousFdoc = usePrevious(fdoc);
    const user = useAuthStore((state) => state.user, shallow);
    const isLoggedIn = useAuthIsLoggedIn();
    const [contentWrapperRef, setContentWrapperRef] = useState<HTMLDivElement | null>(null);
    const containedImage = useContainedImageSize();

    const {
      isAudio,
      isImage,
      isPDF,
      isFileDownloadable,
      isBookmark,
      isNotepad: isNotepadMetadata,
      isNotRenameableStoredFile,
    } = useResourceStoredMetadata(fdoc);

    const { isDesktopView } = useResponsive();

    const [showingShareModal, setShowingShareModal] = useState(false);
    const [itemPreviewContentBodyRef, setItemPreviewContentBodyRef] =
      useState<HTMLDivElement | null>(null);

    const mutationDeleteResources = useMutationDeleteResourcesById();

    const minHeight = useMemo(() => {
      if (isAudio) return 157;
      if (isFileDownloadable) return 470;
      return 350;
    }, [isFileDownloadable, isAudio]);

    const paddingOffsetY = useMemo(() => {
      // this will offset the padding that is removed when the user starts dragging or resizing the modal
      if (
        fdoc.type === 'notepad' ||
        isFileDownloadable ||
        isPDF ||
        isAudio ||
        fdoc.type === 'page' ||
        fdoc.type === 'text'
      )
        return 22;
      return 0;
    }, [fdoc.type, isAudio, isFileDownloadable, isPDF]);

    const [moveable] = useResourceMoveAndResize(
      isFullscreen,
      sidebarRef,
      rootRef,
      contentWrapperRef,
      minHeight,
      expandedFdocSidebarOpen,
      paddingOffsetY,
    );

    const onClickOnExtraZone: (e: React.MouseEvent<HTMLDivElement>) => void = (e) => {
      if (e.target === extraZoneRef) {
        onClose?.();
      }
    };

    const { mutate: mutateUpdateNotepadContent } = useMutationUpdateNotepadContent();

    const [bodyContext, setBodyContext] = useState<BodyContext>({
      scrollPosition: null,
      size: null,
      scrollHeight: 0,
      scrollWidth: 0,
    });

    const { setCursor, setNormalizedScrollTop, setNormalizedScrollLeft, others, following } =
      useSpaceLiveblocksStore(
        (state) => ({
          setCursor: state.setCursor,
          setNormalizedScrollTop: state.setNormalizedScrollTop,
          setNormalizedScrollLeft: state.setNormalizedScrollLeft,
          others: state.liveblocks.others,
          following: state.following,
        }),
        shallow,
      );

    useEffect(() => {
      // make sure the bodyContext is updated with the size and scroll position of itemPreviewContentBodyRef
      if (!itemPreviewContentBodyRef) return;

      const updateBodyContext = () => {
        setBodyContext((bodyContext) => ({
          ...bodyContext,
          scrollPosition: {
            x: itemPreviewContentBodyRef.scrollLeft,
            y: itemPreviewContentBodyRef.scrollTop,
          },
          size: {
            width: itemPreviewContentBodyRef.offsetWidth,
            height: itemPreviewContentBodyRef.offsetHeight,
          },
          scrollHeight: itemPreviewContentBodyRef.scrollHeight,
          scrollWidth: itemPreviewContentBodyRef.scrollWidth,
        }));

        const normalizedScrollTop =
          itemPreviewContentBodyRef.scrollTop / itemPreviewContentBodyRef.scrollHeight;
        const normalizedScrollLeft =
          itemPreviewContentBodyRef.scrollLeft / itemPreviewContentBodyRef.scrollWidth;
        setNormalizedScrollTop(normalizedScrollTop);
        setNormalizedScrollLeft(normalizedScrollLeft);
      };

      updateBodyContext();

      // observer and global scroll listener
      const observer = new ResizeObserver(updateBodyContext);
      observer.observe(itemPreviewContentBodyRef);

      const scrollListener = () => {
        updateBodyContext();
      };

      window.addEventListener('scroll', scrollListener);

      return () => {
        observer.disconnect();
        window.removeEventListener('scroll', scrollListener);
      };
    }, [itemPreviewContentBodyRef, setNormalizedScrollLeft, setNormalizedScrollTop]);

    const throttledMouseMove = useRef(0);
    const throttledTimeout = useRef(0);
    const onMouseMoveHandler = useCallback(
      (e: React.MouseEvent<HTMLDivElement>) => {
        // normalized position based on the size of the content
        const { position, size } = moveable;
        if (!position || !size || !itemPreviewContentBodyRef) return;

        clearTimeout(throttledTimeout.current);

        // if the last mouse move was less than 80ms ago we skip this one
        if (Date.now() - throttledMouseMove.current < 80) {
          throttledTimeout.current = window.setTimeout(() => {
            onMouseMoveHandler(e);
          }, 80);
          return;
        }

        const rect = itemPreviewContentBodyRef.getBoundingClientRect();

        const normalizedX =
          (e.nativeEvent.clientX - rect.left + itemPreviewContentBodyRef.scrollLeft) /
          itemPreviewContentBodyRef.scrollWidth;
        const normalizedY =
          (e.nativeEvent.clientY - rect.top + itemPreviewContentBodyRef.scrollTop) /
          itemPreviewContentBodyRef.scrollHeight;

        setCursor((cursor) => ({
          ...cursor,
          position: { x: normalizedX, y: normalizedY, pressure: null },
        }));

        throttledMouseMove.current = Date.now();
      },
      [moveable, itemPreviewContentBodyRef, setCursor],
    );

    const throttledScroll = useRef(0);
    const throttledScrollTimeout = useRef(0);
    const onScroll = useCallback(
      (e: React.UIEvent<HTMLDivElement>) => {
        if (!e.currentTarget) return;

        if (disableGestures) {
          e.preventDefault();
          return;
        }

        const scrollLeft = e.currentTarget?.scrollLeft ?? 0;
        const scrollTop = e.currentTarget?.scrollTop ?? 0;

        clearTimeout(throttledScrollTimeout.current);

        if (Date.now() - throttledScroll.current < 80) {
          throttledScrollTimeout.current = window.setTimeout(() => {
            onScroll(e);
          }, 80);
          return;
        }

        setNormalizedScrollTop(scrollTop / e.currentTarget.scrollHeight);
        setNormalizedScrollLeft(scrollLeft / e.currentTarget.scrollWidth);

        setBodyContext((bodyContext) => ({
          ...bodyContext,
          scrollPosition: { x: scrollLeft, y: scrollTop },
          scrollHeight: e.currentTarget?.scrollHeight ?? 0,
          scrollWidth: e.currentTarget?.scrollWidth ?? 0,
        }));

        throttledScroll.current = Date.now();
      },
      [setNormalizedScrollTop, setNormalizedScrollLeft, disableGestures],
    );

    useEffect(() => {
      if (!following || !itemPreviewContentBodyRef) return;
      const other = others.find((other) => other.id === following);
      if (!other) return;

      const normalizedScrollTop =
        itemPreviewContentBodyRef.scrollTop / itemPreviewContentBodyRef.scrollHeight;
      const normalizedScrollLeft =
        itemPreviewContentBodyRef.scrollLeft / itemPreviewContentBodyRef.scrollWidth;

      if (
        Math.abs(normalizedScrollTop - other.presence.normalizedScrollTop) < 0.01 &&
        Math.abs(normalizedScrollLeft - other.presence.normalizedScrollLeft) < 0.01
      )
        return;

      // make sure our scroll position is the same as the other user and that we scroll to the same position
      itemPreviewContentBodyRef.scrollTo({
        top: other.presence.normalizedScrollTop * itemPreviewContentBodyRef.scrollHeight,
        left: other.presence.normalizedScrollLeft * itemPreviewContentBodyRef.scrollWidth,
        behavior: 'smooth',
      });

      setNormalizedScrollTop(other.presence.normalizedScrollTop);
      setNormalizedScrollLeft(other.presence.normalizedScrollLeft);
    }, [
      following,
      itemPreviewContentBodyRef,
      others,
      setNormalizedScrollTop,
      setNormalizedScrollLeft,
    ]);

    const [dynamicContent, setDynamicContent] = useState<
      EditorJSData | FabricDataValue | undefined
    >(() => {
      const fdocDataEditorJs = fdoc.type === 'notepad' ? fdoc.data.editorjs : undefined;

      if (fdoc.type !== 'notepad') return;
      if (fdoc.isDraft) {
        if (
          !fdocDataEditorJs ||
          !('blocks' in fdocDataEditorJs) ||
          !fdocDataEditorJs.blocks.some((b) => b.data.text)
        ) {
          return undefined;
        }

        return {
          ...fdocDataEditorJs,
          time: Date.now() + Math.random(),
        };
      }
      return fdocDataEditorJs ?? undefined;
    });

    const dynamicContentRef = useRef(dynamicContent);
    dynamicContentRef.current = dynamicContent;

    const [extraZoneRef, setExtraZoneRef] = useState<HTMLDivElement | null>(null);
    const [topHeaderControlsRef, setTopHeaderControlsRef] = useState<HTMLDivElement | null>(null);
    const [dropdownMenuRef, setDropdownMenuRef] = useState<HTMLUListElement | null>(null);

    const fdocContent = (fdoc.type === 'notepad' ? fdoc.data.editorjs || null : null) as
      | EditorJSData
      | FabricDataValue
      | null;
    const fdocContentRef = useRef(fdocContent);
    fdocContentRef.current = fdocContent;

    const [needsSaving, setNeedsSaving] = useState(false);
    const [isSaving, setSaving] = useState(false);

    useOnWindowClose(needsSaving);

    const fdocTitle = getResourceTitleWithDefault(fdoc);

    /**
     * @TODO naming as useFdocUrl but different, figure out why & usage
     */
    const fdocUrl = useMemo<string | undefined>(() => {
      if (fdoc.isWebnote) return fdoc.data.pageUrl;
      if (fdoc.originUrl) return fdoc.originUrl;
      return undefined;
    }, [fdoc.isWebnote, fdoc.originUrl, fdoc.data]);

    const { mutate: mutateResourceTitleRename, isPending: isResourceTitleRenamePending } =
      useMutationResourceTitleRename();

    const _saveTitle = React.useCallback(
      (value: string) => {
        if (!isStoredFileFdoc(fdoc) && !isNotepadFdoc(fdoc)) return;

        if (fdoc.isDraft) {
          setNeedsSaving(true);
          return;
        }

        if (value !== fdocTitle) {
          mutateResourceTitleRename({
            resource: fdoc,
            newTitle: value,
          });
        }
      },
      [fdoc, mutateResourceTitleRename, fdocTitle],
    );

    const saveTitleDebounced = useDebouncedCallback(_saveTitle, 400);

    const titleInput = useInputControls(fdocTitle, {
      onChange: saveTitleDebounced,
    });

    const titleInputValueRef = useRef(titleInput.sanitizedValue);
    titleInputValueRef.current = titleInput.sanitizedValue;

    const dynamicBinaryContent = useRef<Uint8Array | undefined>(undefined);

    const { track } = useAnalytics();

    /**
     * saving editor stuff
     * though, we should probably move the whole logic of saving the ntoepad content to a separate component
     */
    const saveDisabled =
      isNotRenameableStoredFile ||
      !isLoggedIn ||
      fdoc.isWebnote ||
      fdoc.id !== previousFdoc?.id ||
      (fdoc.type !== 'stored_file' && fdoc.type !== 'notepad') ||
      previousFdoc?.id !== fdoc?.id;

    const handleDraftSaveFdoc = useCallback(
      async (
        title: string,
        newContent: EditorJSData | FabricDataValue | undefined,
        newState?: Uint8Array,
      ) => {
        if (!fdoc.isDraft || fdoc.type !== 'notepad' || saveDisabled) {
          return;
        }

        setSaving(true);

        const newEditorJsContent = dynamicContentRef.current || undefined;

        // we will use the draftSaveFdoc function to save the draft
        const newDraftFdoc: OptimisticDraft<Fdoc> = {
          ...fdoc,
          data: {
            title,
            editorjs: newContent,
            content: undefined,
            isYjsEnabled: false,
            modifiedAt: new Date(),
            createdAt: fdoc.data.createdAt ?? fdoc.createdAt,
          },
        };

        const newId = await draftSaveFdoc?.(newDraftFdoc, newState);

        fdocContentRef.current = newEditorJsContent ?? null;

        setNeedsSaving(false);
        setSaving(false);

        return newId;
      },
      [fdoc, draftSaveFdoc, saveDisabled],
    );

    const updateFdocTitleAndContent = useCallback(async () => {
      if (saveDisabled) {
        return;
      }

      const newEditorJsContent = dynamicContentRef.current || undefined;

      const isTitleSame = titleInputValueRef.current === getResourceTitle(fdoc) || !fdoc.isDraft;

      const isContentSame =
        (newEditorJsContent?.time === fdocContentRef.current?.time || !fdoc.isDraft) &&
        !dynamicBinaryContent.current;

      if (isContentSame && isTitleSame) {
        setNeedsSaving(false);
        return;
      }

      setSaving(true);

      if (fdoc.type === 'notepad') {
        if (fdoc.isDraft) {
          // we will use the draftSaveFdoc function to save the draft
          const title = titleInputValueRef.current;
          const newState = dynamicBinaryContent.current;

          dynamicBinaryContent.current = undefined;
          dynamicContentRef.current = undefined;

          await handleDraftSaveFdoc(title, newEditorJsContent, newState);

          fdocContentRef.current = newEditorJsContent ?? null;

          setNeedsSaving(false);
          setSaving(false);
          return;
        }

        if (dynamicBinaryContent.current) {
          mutateUpdateNotepadContent({
            notepad: fdoc,
            content: newEditorJsContent as TiptapDataValue,
            state: dynamicBinaryContent.current,
          });

          fdocContentRef.current = newEditorJsContent ?? null;
          dynamicBinaryContent.current = undefined;
        }
      }

      setNeedsSaving(false);
      setSaving(false);
    }, [saveDisabled, fdoc, handleDraftSaveFdoc, mutateUpdateNotepadContent]);

    /**
     * debounced save - used to save the notepad content
     * after 10 seconds of inactivity
     */
    const debouncedSave = useDebouncedCallback(updateFdocTitleAndContent, 10000);
    const saveChangesRef = useRef(debouncedSave);
    saveChangesRef.current = debouncedSave;

    /**
     * Debounced optimistic update cache
     * used to update the cache with the new content when not in offline mode
     */

    const optimisticNotepadStateUpdate = useOptimisticNotepadStateUpdate();
    const { mutate: mutateNotepadState, isPending: isNotepadStatePending } =
      useMutationNotepadStateUpdate();

    const throttledTrackNotepadEdit = useThrottledCallback(() => {
      track(AnalyticsEvents.NotepadEdit);
    }, 1000);

    const notepadContentRef = useRef<TiptapDataValue | undefined>(undefined);

    const onEditorChange = useCallback(
      (data: TiptapDataValue, state: Uint8Array) => {
        setEditorMarkdown(data.blocks.map((block) => block.data.text).join('\n'));

        if (fdoc.isDraft) {
          setNeedsSaving(true);
          setDynamicContent(data);
          dynamicContentRef.current = data;
          dynamicBinaryContent.current = state;
          saveChangesRef.current();
          throttledTrackNotepadEdit.current();
          return;
        }

        throttledTrackNotepadEdit.current();
        notepadContentRef.current = data;
      },
      [fdoc.isDraft, throttledTrackNotepadEdit],
    );

    const onOfflineChange = useCallback(
      (update: Uint8Array, inMultiplayer?: boolean) => {
        if (!isNotepadFdoc(fdoc) || fdoc.isDraft) return;

        const changes = {
          resource: fdoc,
          update,
          optimisticContent: notepadContentRef.current,
        };

        if (inMultiplayer) {
          optimisticNotepadStateUpdate(changes);
          return;
        }
        mutateNotepadState(changes, {
          onSuccess: () => {
            setNeedsSaving(false);
          },
        });
      },
      [fdoc, mutateNotepadState, optimisticNotepadStateUpdate],
    );

    const handleOnCreateFromImage = useMemo(
      () =>
        fdoc?.isDraft
          ? async () => {
              if (fdoc.type !== 'notepad') return;
              return await handleDraftSaveFdoc(
                titleInputValueRef.current,
                dynamicContentRef.current,
                dynamicBinaryContent.current,
              );
            }
          : undefined,
      [fdoc, handleDraftSaveFdoc],
    );

    const uploadNotepadImage = useUploadNotepadImage(fdoc.id, handleOnCreateFromImage);

    const notepadConnectionStateRef = useRef(notepadConnectionState);
    notepadConnectionStateRef.current = notepadConnectionState;

    /**
     * save on unmount
     */
    useUnmount(() => {
      updateFdocTitleAndContent();
      if ('cancel' in saveChangesRef.current) {
        saveChangesRef.current.cancel();
      }
    });

    const handleAddToSpace = () => {
      if (!fdoc?.id) return;

      setGlobalSelectionOptions({
        selectedFdocsIds: [fdoc.id],
        clearOnClose: true,
      });
    };

    const handleCopySourceURL = () => {
      if (!fdoc?.id || !fdoc.isWebnote) return;

      navigator.clipboard.writeText(fdoc.data.pageUrl);
    };
    const providerValue = useMemo(
      () => ({
        itemPreviewContentBodyRef,
        topHeaderControlsRef,
        extraZoneRef,
        dropdownMenuRef,
        isFullscreen,
        onClose,
      }),
      [
        itemPreviewContentBodyRef,
        topHeaderControlsRef,
        extraZoneRef,
        dropdownMenuRef,
        isFullscreen,
        onClose,
      ],
    );

    const { windowWidth } = useResponsive();
    const isMobile = useMemo(() => windowWidth < 640, [windowWidth]);

    const [headerRef, setHeaderRef] = useState<HTMLElement | null>(null);
    const [closing, setClosing] = useState(false);
    const { offsetY } = useSwipe(
      headerRef,
      {
        onSwipeDown: () => {
          if (!onClose) return;

          setClosing(true);

          setTimeout(() => {
            onClose?.();
          }, 50);
        },
      },
      {
        sensitivity: 150,
        sensitivityPreventPropagation: 150,
        disabled: !isMobile || !onClose || disableGestures,
      },
    );

    const [tinyPagesIsVisible, setTinyPagesIsVisible] = useState(true);

    const downloadFdoc = useDownloadFdoc();

    const handleDownloadFile = () => {
      downloadFdoc(fdoc);
    };

    const onEditorFocusChanged = useCallback(
      (isFocused: boolean) => {
        setIsEditorFocused(isFocused);
      },
      [setIsEditorFocused],
    );

    const toggleFullscreen = useCallback(() => {
      setIsFullscreen((isFullscreen) => !isFullscreen);
    }, [setIsFullscreen]);

    const savingText = useMemo(() => {
      if (fdoc.isDraft) return 'Not saved yet';
      if (notepadConnectionState === 'LOADING') return 'Loading...';
      if (notepadConnectionState === 'OFFLINE') {
        if (isSaving || isNotepadStatePending) return 'Saving...';
        if (needsSaving) return 'Pending Changes';
        return 'Saved';
      }
      if (notepadConnectionState === 'CONNECTING') return 'Connecting...';
      return `Connected and syncing`;
    }, [fdoc.isDraft, notepadConnectionState, needsSaving, isSaving, isNotepadStatePending]);

    const [editTagsModalOpen, setEditTagsModalOpen] = useState(false);

    const mutationResourcesTagAssign = useMutationResourcesTagAssign();
    const queryResourceTags = useQueryResourceTags(fdoc.id, {
      enabled: !fdoc.isDraft,
    });

    // ── suggestions logic ───────────────────────────────────────────────
    const { mutate: mutateMoveResourcesById } = useMutationMoveResourcesById();
    const { onSelectSuggestion: onSelectSuggestionDraft } = useNewResourceContext();
    const [editorMarkdown, setEditorMarkdown] = useState<string | null>(null);

    const inlineSuggestionsEnabled = fdoc.isDraft || fdoc.draftId;

    const suggestionsContext: SuggestionContext | undefined =
      // We make sure the fdoc is either a draft OR has a draft id (just created)
      (!!titleInput.value || !!editorMarkdown || !fdoc.isDraft) &&
      (inlineSuggestionsEnabled || editTagsModalOpen)
        ? fdoc.isDraft
          ? {
              name: titleInput.value ?? undefined,
              otherInfo: prettifyForLLM({
                notepadContent: editorMarkdown,
              }),
            }
          : {
              resourceId: fdoc.id,
            }
        : undefined;

    /**
     * Makes sure any pasted content is propery loaded into the suggestion context.
     */
    const onEditorInitialized = (value: TiptapDataValue) => {
      if (fdoc.isDraft) return;

      setEditorMarkdown(value.blocks.map((block) => block.data.text).join('\n'));
    };

    const { mutateAsync: mutateTagCreate } = useMutationTagCreate();

    const onSelectTag = useCallback(
      (tag: PrivateTag, selected: boolean) => {
        if (!fdoc || fdoc.isDraft) {
          draftOnSelectTag?.(tag, selected);
          return;
        }

        mutationResourcesTagAssign.mutate({
          tag,
          resourceIds: [fdoc.id],
          operation: selected ? 'assign' : 'unassign',
        });
      },
      [mutationResourcesTagAssign, fdoc, draftOnSelectTag],
    );

    const onSelectSuggestion = async (suggestion: SuggestionItem) => {
      if (fdoc.isDraft || draftOnSelectTag) return onSelectSuggestionDraft(suggestion);

      if (suggestion.type === SuggestionItemType.TAG && suggestion.tag) {
        onSelectTag(
          suggestion.tag.isDraft
            ? await mutateTagCreate({
                tagName: suggestion.name,
                action: 'suggestion',
              })
            : suggestion.tag,
          true,
        );
      } else if (suggestion.type === SuggestionItemType.FOLDER && suggestion.id)
        mutateMoveResourcesById({
          newParent: {
            id: suggestion.id,
            name: suggestion.name,
          },
          resourceIds: [fdoc.id],
        });
    };

    const newModalOptions = useUIStore((s) => s.newModalOptions, shallow);

    const pastedContent =
      fdoc.isDraft && fdoc.type === 'notepad'
        ? newModalOptions.dataTransfer ?? newModalOptions.text
        : undefined;

    return (
      <MagicSuggestionsContextProvider
        onSelectSuggestion={onSelectSuggestion}
        context={suggestionsContext}
        includeFolders={Boolean(fdoc.isDraft || fdoc.draftId)}
      >
        <ExpandedFdocContentContext.Provider value={providerValue}>
          <div
            data-testid="expanded-fdoc-main-content"
            className={clsx(styles.content_wrapper, {
              [styles.content_wrapper__downloadable]: isFileDownloadable,
              [styles.content_wrapper__audio]: isAudio,
              [styles.content_wrapper__image]: isImage,
              [styles.content_wrapper__image__loading]: isImage && containedImage.loaded,
              [styles.content_wrapper__bookmark]: isBookmark,
              [styles.content_wrapper__notepad]: isNotepadMetadata,
              [styles.fullscreen]: isFullscreen && isDesktopView,
              [styles.no_gestures]: disableGestures,
            })}
            style={
              moveable.moved
                ? {
                    maxWidth: 'none',
                    maxHeight: 'none',
                    width: 'auto',
                    height: 'auto',
                    position: 'fixed',
                    left: moveable.position?.x ?? 0,
                    top: moveable.position?.y ?? 0,
                    padding: 0,
                  }
                : {}
            }
          >
            <div className={styles.closer} onClick={onClose} />
            {showingShareModal && fdoc && (
              <ShareModal
                resourceId={fdoc.id}
                onClose={() => setShowingShareModal(false)}
                sharingObject="item"
              />
            )}
            <div className={styles.extra} ref={setExtraZoneRef} onClick={onClickOnExtraZone} />
            <div
              className={clsx(styles.content_main, styles.content_wrapper, {
                [styles.content_wrapper__bookmark]: isBookmark,
                [styles.content_wrapper__downloadable]: isFileDownloadable,
                [styles.content_wrapper__image]: isImage,
                [styles.content_wrapper__image__loading]: isImage && containedImage.loaded,
                [styles.content_main__closing]: closing,
                [styles.content_main__editor_focused]: isEditorFocused,
                [styles.content_wrapper__pdf]: isPDF,
              })}
              style={{
                ...(moveable.moved
                  ? {
                      width: moveable.size?.width ?? undefined,
                      height: moveable.size?.height ?? undefined,
                      padding: 0,
                      maxWidth: 'none',
                      maxHeight: 'none',
                    }
                  : {
                      maxWidth:
                        isImage && containedImage.imgWidth
                          ? Math.max(containedImage.imgWidth, MIN_WIDTH)
                          : undefined,
                      maxHeight: isImage ? '100%' : undefined,
                    }),
                ...({
                  '--offset-y': isMobile && !closing ? `${offsetY}px` : undefined,
                } as React.CSSProperties),
              }}
              ref={setContentWrapperRef}
            >
              <div className={styles.extra} ref={setExtraZoneRef} onClick={onClickOnExtraZone} />
              <div
                className={clsx(
                  styles.item_preview__content,
                  !isDesktopView && styles.item_preview__content__not_desktop,
                )}
                style={
                  hideBackdrop // no backdrop so we add border for visual separation
                    ? {
                        border: `1px solid ${cssVar['color-border-primary']}`,
                      }
                    : undefined
                }
              >
                <StyledTagButton
                  onSelect={onSelectTag}
                  selectedTags={draftTags ?? queryResourceTags.data ?? []}
                  open={editTagsModalOpen}
                  onOpenChange={setEditTagsModalOpen}
                />
                {moveable.resizeUiHandlers}

                <div
                  className={styles.item_preview__content__body}
                  style={isFullscreen ? { borderBottomLeftRadius: '0px' } : {}}
                >
                  <header
                    data-movable-area="true"
                    className={clsx(
                      styles.item_preview__content__header,
                      isFileDownloadable && styles.item_preview__content__header__downloadable,
                    )}
                    data-testid="item-preview-content-header"
                    onMouseDown={moveable.onPositionMouseDown}
                    ref={setHeaderRef}
                  >
                    {isFullscreen && isPDF && (
                      <ButtonIcon
                        style={{ marginLeft: 18, marginRight: -18 }}
                        onClick={() => setTinyPagesIsVisible((prev) => !prev)}
                        variant="bg-secondary"
                      >
                        <MenuIcon />
                      </ButtonIcon>
                    )}

                    {isProcessing && (
                      <StyledSpinnerContainer>
                        <Tooltip label="Waiting to be indexed and analyzed by AI">
                          <StateSpinner size={22} thickness={3} isIndexing />
                        </Tooltip>
                      </StyledSpinnerContainer>
                    )}

                    {fdocUrl ? (
                      <ResourceTitleUrl resourceUrl={fdocUrl} title={fdocTitle} resource={fdoc} />
                    ) : (
                      !isFileDownloadable && (
                        <ResourceTitleEditable
                          titleInput={titleInput}
                          setIsEditorFocused={setIsEditorFocused}
                          resource={fdoc}
                          user={user}
                          onSubmit={saveTitleDebounced}
                        />
                      )
                    )}
                    <div className={styles.content_header_controls}>
                      {fdoc.type === 'notepad' && (
                        <Tooltip label={savingText} delay={0}>
                          <span>
                            {(notepadConnectionState === 'LOADING' ||
                              notepadConnectionState === 'CONNECTING' ||
                              notepadConnectionState === 'CONNECTED' ||
                              (notepadConnectionState === 'OFFLINE' &&
                                (needsSaving || fdoc.isDraft || isResourceTitleRenamePending))) && (
                              <UpdateIcon
                                data-testid="fdoc-needs-saving-icon"
                                className={
                                  isSaving ||
                                  isNotepadStatePending ||
                                  notepadConnectionState === 'CONNECTING' ||
                                  isResourceTitleRenamePending
                                    ? 'animate-spin'
                                    : undefined
                                }
                                style={{
                                  color: 'var(--fabric-color-text-tertiary)',
                                  width: 28,
                                  height: 'auto',
                                }}
                              />
                            )}

                            {(notepadConnectionState === 'SYNCED' ||
                              (notepadConnectionState === 'OFFLINE' &&
                                !needsSaving &&
                                !fdoc.isDraft &&
                                !isResourceTitleRenamePending)) && (
                              <CloudTickIcon
                                data-testid="fdoc-saved-icon"
                                style={{
                                  width: 28,
                                  height: 'auto',
                                }}
                              />
                            )}
                          </span>
                        </Tooltip>
                      )}
                      <div
                        className={styles.top_header_controls_extra}
                        ref={setTopHeaderControlsRef}
                      />
                      {(fdoc.type === 'notepad' || isPDF) && isDesktopView && (
                        <ButtonIcon
                          onClick={toggleFullscreen}
                          data-testid="fullscreen-preview-button"
                          tabIndex={4}
                          variant="bg-secondary"
                        >
                          {isFullscreen ? (
                            <ExitFullscreenIcon style={{ width: 20, height: 20 }} />
                          ) : (
                            <FullscreenIcon />
                          )}
                        </ButtonIcon>
                      )}
                      <DesktopTagButton
                        onSelect={onSelectTag}
                        selectedTags={draftTags ?? queryResourceTags.data ?? []}
                        open={editTagsModalOpen}
                        onOpenChange={setEditTagsModalOpen}
                      />
                      {fdoc.type === 'stored_file' && isPDF && (
                        <ButtonIconDownload onClick={handleDownloadFile} tabIndex={4} />
                      )}
                      {(fdoc.user?.id === user?.id ||
                        (fdoc.type === 'stored_file' &&
                          fdoc.data.contentType === 'application/pdf')) && (
                        <DropdownMenu_Deprecated
                          shadedTrigger
                          headlessTrigger
                          triggerElement={<ButtonIconDotMenu />}
                          tabIndex={4}
                        >
                          <ul ref={setDropdownMenuRef} />
                          {fdoc.isWebnote && (
                            <button
                              tabIndex={5}
                              onClick={handleCopySourceURL}
                              data-testid="fdoc-copy-source-url-button"
                            >
                              Copy original URL
                            </button>
                          )}

                          <button onClick={() => setEditTagsModalOpen(true)}>Add/edit tags</button>

                          {fdoc.user?.id === user?.id && fdoc.listData?.type !== 'INTEGRATION' && (
                            <>
                              <button
                                onClick={handleAddToSpace}
                                data-testid="fdoc-add-to-space-button"
                                tabIndex={5}
                              >
                                Move...
                              </button>
                              <button onClick={() => setShowingShareModal(true)} tabIndex={5}>
                                {fdoc?.isDirectShared ? 'Manage sharing' : 'Share'}
                              </button>
                              {fdoc && (
                                <button
                                  onClick={() =>
                                    mutationDeleteResources.confirmAndMutate([fdoc.id], {
                                      onSuccess: onClose,
                                    })
                                  }
                                  data-testid="fdoc-delete-button"
                                  tabIndex={5}
                                >
                                  Delete
                                </button>
                              )}
                            </>
                          )}
                        </DropdownMenu_Deprecated>
                      )}
                      {onClose && (
                        <Modal.Close
                          // changed from onClick the modal now has onOpenChange so closing is handled there
                          // onTouchEnd is necessary, HOTFIX, on mobile (ios app, safari) the onClick is not being triggered
                          onPointerUp={onClose}
                          data-testid="close-preview-button"
                          tabIndex={4}
                        />
                      )}
                    </div>
                  </header>

                  <div className={clsx(styles.item_preview__split_body)} ref={ref}>
                    <div
                      className={clsx(styles.item_preview__content__body, 'dashboard_scrollbar')}
                      onMouseMove={onMouseMoveHandler}
                      onScroll={onScroll}
                      ref={setItemPreviewContentBodyRef}
                    >
                      <SpaceCursors bodyContext={bodyContext} expandedFdocId={fdoc.id} />

                      {fdoc.type === 'text' && (
                        <div className={styles.item_preview__content__body__text}>
                          <span className={styles.item_preview__content__body__text__highlight}>
                            {noteTextFormat(fdoc.data.text)}
                          </span>
                        </div>
                      )}

                      <EditTagsModal
                        onSelect={onSelectTag}
                        selectedTags={draftTags ?? queryResourceTags.data ?? []}
                        open={editTagsModalOpen}
                        onOpenChange={setEditTagsModalOpen}
                      />
                      {isNotepadFdoc(fdoc) && (
                        <MultiplayerEditor
                          notepad={fdoc}
                          onOfflineChange={onOfflineChange}
                          onMultiplayerStateChange={onMultiplayerStateChange.current}
                          onHasPendingChanges={onHasPendingChanges.current}
                          tiptapProps={{
                            editable: isLoggedIn,
                            initialValue: dynamicContent,
                            onChange: onEditorChange,
                            onInitialize: onEditorInitialized,
                            onEditorFocusChange: onEditorFocusChanged,
                            onUploadImage: uploadNotepadImage ?? undefined,
                            tabIndex: '10',
                            autoFocus: fdoc.isDraft,
                            contentClassName: styles.tiptap_editor,
                            pastedContent,
                            bottomElement: fdoc.isDraft ? (
                              <Flex
                                direction="column"
                                gap="sectionsShort"
                                style={{
                                  padding: '13px 8px',
                                }}
                              >
                                {fdoc.isDraft && <CustomDestinationButton />}
                                <MagicSuggestions disabled={!inlineSuggestionsEnabled} />
                              </Flex>
                            ) : (
                              <MagicSuggestions
                                style={{
                                  padding: '13px 8px',
                                }}
                                disabled={!inlineSuggestionsEnabled}
                              />
                            ),
                          }}
                        />
                      )}
                      {fdoc.type === 'stored_file' && (
                        <StoredFileContent
                          fdoc={fdoc}
                          imageRef={containedImage.imageRef}
                          isFullscreenEnabled={isFullscreen}
                          tinyPagesIsVisible={tinyPagesIsVisible}
                          disableGestures={disableGestures}
                        />
                      )}
                      {fdoc.type === 'image' && (
                        <MediaContent
                          fdoc={fdoc}
                          imageRef={containedImage.imageRef}
                          disableGestures={disableGestures}
                        />
                      )}

                      {fdoc.type === 'page' && <PageResource resource={fdoc} />}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ExpandedFdocContentContext.Provider>
      </MagicSuggestionsContextProvider>
    );
  },
);

ExpandedFdocContent.displayName = 'ExpandedFdocContent';

const CustomDestinationButton = styled(DestinationButton)`
  position: static;
  bottom: unset;
  left: unset;
  transform: unset;
  align-self: center;
  color: white;
  transition: 0.2s all;
`;

export default ExpandedFdocContent;
