'use client';

import { useResponsive } from '@/src/hooks/responsive';
import { useRunOnce } from '@/src/hooks/useRunOnce';
import { restoreDataTransfer, StoredDataTransfer } from '@/src/lib/clipboard';
import Collaboration from '@tiptap/extension-collaboration';
import CollaborationCursor from '@tiptap/extension-collaboration-cursor';
import { Transaction } from '@tiptap/pm/state';
import { Editor, EditorContent, useEditor } from '@tiptap/react';
import clsx from 'clsx';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { defaultSelectionBuilder } from 'y-prosemirror';
import { WebsocketProvider } from 'y-websocket';
import * as Y from 'yjs';
import FixedToolbar from './Components/FixedToolbar';
import InlineToolbar from './Components/InlineToolbar';
import tiptapExtensions from './Extensions';
import BubbleImageOptions from './Extensions/BubbleImageOptions';
import Resizer from './Extensions/Resizer';
import styles from './Tiptap.module.scss';
import { convertAllToTiptapValue, getTiptapDataValue } from './content';
import { AllNotepadValues, TiptapDataValue } from './types';

export type TiptapProps = {
  pastedContent?: StoredDataTransfer | string;
  initialValue?: AllNotepadValues | null;
  className?: string;
  contentClassName?: string;
  style?: React.CSSProperties;
  contentStyle?: React.CSSProperties;
  editable?: boolean;
  disableScrolling?: boolean;
  onChange?: (content: TiptapDataValue, binary: Uint8Array, transaction?: Transaction) => void;
  onInitialize?: (content: TiptapDataValue) => void;
  editorRef?: React.MutableRefObject<Editor | null> | ((editor: Editor) => void);
  onEditorFocusChange?: (isFocused: boolean) => void;
  onUploadImage?: (file: File) => Promise<string>;
  allowMultiplayer?: boolean;
  autoFocus?: boolean;
  tabIndex?: string;
  yDoc?: Y.Doc;
  onlineProvider?: WebsocketProvider;

  onToolbarHeightChange?: (height: number) => void;
  userAwareness?: {
    name: string;
    color: string;
  } | null;

  bottomElement?: React.ReactNode;
};

const Tiptap: React.FC<TiptapProps> = ({
  pastedContent,
  initialValue,
  className,
  contentClassName,
  editable = true,
  disableScrolling = false,
  style,
  onChange,
  onInitialize,
  editorRef,
  onEditorFocusChange,
  onUploadImage,
  autoFocus,
  tabIndex,
  yDoc: yDocProp,
  onlineProvider: provider,
  userAwareness,
  onToolbarHeightChange,
  bottomElement,
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [contentRef, setContentRef] = useState<HTMLDivElement | null>(null);
  const { isMobileView } = useResponsive();
  const [initialized, setInitialized] = useState(false);

  const yDoc = useMemo(() => yDocProp ?? new Y.Doc(), [yDocProp]);

  const extensions = useMemo(
    () => [
      ...tiptapExtensions,
      Collaboration.configure({
        document: yDoc,
      }),
      ...(provider
        ? [
            CollaborationCursor.configure({
              provider,
              user: userAwareness ?? undefined,
              selectionRender: (user) => {
                const defaultSelection = defaultSelectionBuilder(user);

                return {
                  ...defaultSelection,
                  style: `${defaultSelection.style}; --yjs-cursor-color: ${user.color}bb;`,
                };
              },
            }),
          ]
        : []),
    ],
    [yDoc, provider, userAwareness],
  );

  const blurTimeout = useRef<number | undefined>(undefined);

  const scrollTop = useRef(0);
  const scrollLeft = useRef(0);

  const editor = useEditor({
    content: (initialValue ? convertAllToTiptapValue(initialValue) : undefined)?.content,
    extensions,
    autofocus: autoFocus,
    editable,
    onUpdate: ({ editor, transaction }) => {
      if (!transaction.docChanged) return;

      const content = getTiptapDataValue(editor as Editor);
      onChange?.(content, Y.encodeStateAsUpdate(yDoc), transaction);
    },
    onTransaction: ({ editor }) => {
      if (initialized) return;

      setInitialized(true);
      onInitialize?.(getTiptapDataValue(editor as Editor));
    },
    editorProps: {
      attributes: {
        class: clsx(styles.tiptap, styles['tiptap-node-styles'], contentClassName),
        ...(!editable
          ? { tabindex: '-1' }
          : {
              ...(tabIndex && { tabindex: tabIndex }),
            }),
      },
    },
    onFocus: () => {
      window.clearTimeout(blurTimeout.current);
      onEditorFocusChange?.(true);
    },
    onBlur: () => {
      blurTimeout.current = window.setTimeout(() => {
        // if data-role='expanded-fdoc-title-input' we don't want to blur
        if ((document.activeElement as HTMLElement)?.dataset?.role === 'expanded-fdoc-title-input')
          return;

        // if the active element has data-tiptap-toolbar or is a child of it, we don't want to blur
        const activeElement = document.activeElement as HTMLElement;
        if (
          activeElement?.dataset?.tiptapToolbar ||
          activeElement?.closest('[data-tiptap-toolbar]')
        ) {
          return;
        }

        onEditorFocusChange?.(false);
      }, 10);
    },
    onCreate: ({ editor }) => {
      setTimeout(() => {
        /**
         * for some reason the empty flag includes html tags, if there are any, it's treated as non-empty
         * so we need to check for text length as well
         */
        if (editor.isEmpty || editor.getText().trim().length === 0) {
          editor.commands.focus();
        }

        if (containerRef.current) {
          const editorElement = containerRef.current.querySelector('.ProseMirror');
          if (!editorElement) return;

          setContentRef(editorElement as HTMLDivElement);
        }
        /**
         * doesn't work without timeout, either something is stealing focus or dom is not fully rendered yet
         */
      }, 100);

      editor.commands.setOnUpload(onUploadImage);
    },
  });

  useEffect(() => {
    if (!editor || !userAwareness || !provider) return;

    editor.commands.updateUser(userAwareness);
  }, [editor, userAwareness, provider]);

  useEffect(() => {
    if (!editor) return;

    if (typeof editorRef === 'object') {
      editorRef.current = editor;
    } else if (typeof editorRef === 'function') {
      editorRef(editor);
    }
  }, [editor, editorRef]);

  const onClickContainer = (e: React.MouseEvent) => {
    if (e.target !== e.currentTarget || !editor) return;

    editor.commands.focus();
    e.preventDefault();
  };

  /**
   * For pasted content, we need to simulate a paste event, this is because Tiptap cleans and and parses the content
   * from the event, including non plain text content, e.g. markdown, html, etc.
   * And this is lost when we simply set the text into the editor value.
   *
   * Although Tiptap does try to clean up content placed directly on the editor it doesn't trigger onUpdate transactions
   * that means when creating a new notepad with pasted content, the content formatting would be lost. This prevents that.
   */
  useRunOnce(
    () => {
      if (!editor || !pastedContent) return;

      let dataTransfer: DataTransfer | undefined;
      if (typeof pastedContent === 'string') {
        dataTransfer = new DataTransfer();
        dataTransfer.setData('text/plain', pastedContent);
      } else dataTransfer = restoreDataTransfer(pastedContent);

      const dom = editor.view.dom;
      const event = new ClipboardEvent('paste', {
        clipboardData: dataTransfer,
        composed: true,
      });

      dom.dispatchEvent(event);
    },
    Boolean(editor && pastedContent),
  );

  return (
    <div
      className={clsx(styles.editor, disableScrolling && styles.disableScrolling, className)}
      style={style}
      ref={containerRef}
      onClick={onClickContainer}
      data-tiptap-editor-container
      onTouchMove={(e) => {
        if (!editor?.isFocused) return;

        /**
         * Couldn't find why, disabled a bunch of move events, pan logic, and still didn't solve the issue but something is
         * preventing the touch move from running within the notepad, which makes it so the selection handles in iOS stop working
         * Android works 100% fine, so this is a workaround for iOS
         * We stop propogation so the event doesn't bubble up to where there is potentially some issue causing this
         */
        e.stopPropagation();
      }}
    >
      {editable && editor && (
        <FixedToolbar
          onToolbarHeightChange={onToolbarHeightChange}
          key="tiptap-fixed-toolbar"
          editor={editor}
        />
      )}
      <div data-tiptap-bottom>{bottomElement}</div>

      <EditorContent
        key="tiptap-editor-content"
        className="dashboard_scrollbar"
        editor={editor}
        data-or-obscured
        data-tiptap-content-scrollbar
        data-tiptap-editor-content
        onScroll={(e) => {
          scrollTop.current = e.currentTarget.scrollTop;
          scrollLeft.current = e.currentTarget.scrollLeft;
        }}
      />
      {editable && editor && <BubbleImageOptions key="tiptap-image-options" editor={editor} />}
      {editable && editor && contentRef && !isMobileView && (
        <InlineToolbar key="tiptap-inline-toolbar" editor={editor} contentRef={contentRef} />
      )}
      {editable && editor && <Resizer editor={editor} />}
    </div>
  );
};

export default Tiptap;
