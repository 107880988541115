import React, { useMemo } from 'react';

import CheckIcon from '@/public/images/icons/Check.svg';
import PlusIcon from '@/public/images/icons/Plus.svg';
import { ApiColorLabel } from '@/src/modules/labels/labels.types';
import { useMutationChangeResourceLabel } from '@/src/modules/resource-detail/mutations/useMutationChangeResourceLabel';
import { Space } from '@/src/modules/spaces/spaces.types';
import { Fdoc } from '@/src/types/api';
import { getTextColor, sortColorLabels } from '@/src/utils/color';
import clsx from 'clsx';
import styles from './LabelSelector.module.scss';

const LabelSelector: React.FC<{
  fdocs: Fdoc[];
  colorLabels: ApiColorLabel[];
  space: Space;
}> = ({ fdocs, space, colorLabels }) => {
  const sortedColorLabels = useMemo(() => sortColorLabels(colorLabels), [colorLabels]);

  const [hoveredLabel, setHoveredLabel] = React.useState<ApiColorLabel | null>(null);

  const mutationChangeResourceLabel = useMutationChangeResourceLabel();

  const handleLabelClick = async (colorLabel: ApiColorLabel) => {
    if (fdocs.length < 1 || !space) return;

    /**
     * if every fdoc has the selected label - we unset the label
     * otherwise if it's a mix, we set the selected label to all fdocs
     */
    const nextLabel: ApiColorLabel | null = fdocs.every((fdoc) => fdoc.label === colorLabel.id)
      ? null
      : colorLabel;

    try {
      fdocs.forEach((fdoc) => {
        mutationChangeResourceLabel.mutate({
          resourceId: fdoc.id,
          spaceId: space.id,
          label: nextLabel,
        });
      });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className={styles.labelSelector}>
      {sortedColorLabels.map((colorLabel) => {
        const selected =
          fdocs.find((f) => f.label === colorLabel.id) &&
          (fdocs.length < 2 || (fdocs.length > 1 && hoveredLabel !== colorLabel));
        return (
          <button
            className={clsx(
              styles.bubbleButton,
              selected && styles.selected,
              fdocs.length > 1 &&
                hoveredLabel &&
                selected &&
                hoveredLabel !== colorLabel &&
                styles.otherHover,
            )}
            style={{
              color: getTextColor(colorLabel.hexColor),
            }}
            key={colorLabel.id}
            onClick={() => handleLabelClick(colorLabel)}
            onMouseEnter={() => setHoveredLabel(colorLabel)}
            onMouseLeave={() => setHoveredLabel(null)}
          >
            <span style={{ backgroundColor: colorLabel.hexColor }} />

            <PlusIcon className={styles.plusIcon} />
            <CheckIcon className={styles.checkIcon} />
          </button>
        );
      })}
    </div>
  );
};

export default LabelSelector;
