import InvertedFabricIcon from '@/public/images/icons/Fabric.svg';
import {
  ChatbotConversationMessage,
  ChatbotConversationUserAction,
  ChatbotConversationUserActionType,
} from '@/src/modules/assistant/types';
import { parseMarkdown } from '@/src/modules/markdown/markdown.utils';
import { ResourcePreview } from '@/src/modules/resources/components/ResourcePreview/ResourcePreview';
import { useQueryResourceDetail } from '@/src/modules/resources/queries/useQueryResourceDetail';
import * as Conversation from '@/src/modules/ui/components/Conversation';
import { Flex } from '@/src/modules/ui/components/Flex';
import { PortalPrepend } from '@/src/modules/ui/components/PortalPrepend';
import { renderCssBasedOnColorScheme } from '@/src/modules/ui/theme/renderCssBasedOnColorScheme';
import { cssVar } from '@/src/modules/ui/theme/variables';
import Avatar from '@/src/modules/user/components/Avatar/Avatar';
import AvatarUser from '@/src/modules/user/components/AvatarUser/AvatarUser';
import { isChatbotAssistantMessage, isChatbotUserMessage } from '@/src/modules/utils';
import { ChatbotMessageRole } from '@fabric/woody-client';
import { useEffect, useMemo, useRef } from 'react';
import styled, { css } from 'styled-components';
import { shallow } from 'zustand/shallow';
import useAuthStore from '../../hooks/auth';
import { useShowExpandedFdoc } from '../../hooks/useExpandedFdoc';

function isValidUUID(uuid: string): boolean {
  const regex =
    /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/;
  return regex.test(uuid);
}

const RenderedContentWrapper = styled.div<{ isResourceList?: boolean }>`
  ${(p) =>
    p.isResourceList &&
    css`
      ol,
      ul {
        margin-bottom: 0;
        margin-top: 0.5rem;
        li {
          margin-top: 0;
          margin-bottom: 1rem;
          &:after {
            content: '';
            display: block;
            clear: both;
          }

          div[data-prepend-portal='true'] {
            float: left;
            margin-right: 1rem;
            [data-preview-resource-external-link] {
              display: none;
            }
          }
        }
        & > * {
          margin-top: 1rem;
        }
      }
    `}

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 700;
  }

  overflow-wrap: break-word;
  word-break: break-word;
  hyphens: auto;

  a {
    display: inline;
  }

  line-height: 18px;
  word-wrap: break-word;
  hyphens: auto;

  white-space: pre-wrap;

  p:not([class]) {
    white-space: pre-wrap;
  }

  br:not([class]) {
    display: none;
  }

  > *:not(p) {
    white-space: normal;
  }

  user-select: text;
  * {
    user-select: text;
  }

  a:not([class]) {
    color: rgb(var(--fabric-color-primary));
    text-decoration: underline;
  }

  code:not([class]) {
    font-family: 'Fira Code', monospace;
    font-size: 13px;
    background: var(--fabric-color-bg-tertiary);
    padding: 2px 4px;
    border-radius: 4px;
    color: var(--fabric-color-text-tertiary);
  }

  pre:not([class]) {
    font-family: 'Fira Code', monospace;
    font-size: 13px;
    background: var(--fabric-color-bg-tertiary);
    padding: 8px;
    border-radius: 4px;
    color: var(--fabric-color-text-tertiary);
    overflow-x: auto;
    white-space: pre-wrap;

    > code {
      background: none;
      padding: 0;
      border-radius: 0;
    }
  }

  ul:not([class]) {
    // add normal list styling
    list-style-type: disc;
    padding-left: 1.5rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;

    > li {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }
  }

  ol:not([class]) {
    // add normal list styling
    list-style-type: decimal;
    padding-left: 1.5rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;

    > li {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }
  }
`;

const RenderedContent: React.FC<{ content: string; isResourceList?: boolean }> = ({
  content = '',
  isResourceList,
}) => {
  /**
   * This component is used to render the content of a message.
   *
   * The content is a string and resource links are in the format [text](fabric:resourceId).
   *
   * We first convert the links to markdown, then we render the markdown.
   *
   * We also add a click handler on the links to expand the resources instead of opening the link.
   */
  const expand = useShowExpandedFdoc();
  const divRef = useRef<HTMLDivElement>(null);

  const markdownContent = useMemo(() => {
    return parseMarkdown(content || '', {
      includeEmptyLines: false,
    });
  }, [content]);

  useEffect(() => {
    const div = divRef.current;

    const linkClickHandler = (e: Event) => {
      const target = e.target as HTMLElement;
      if (target.tagName === 'A') {
        const href = (target as HTMLAnchorElement).href;
        if (href.startsWith('fabric://')) {
          let resourceId;
          try {
            resourceId = href.split('://')[1];
          } catch (e) {
            console.error(e);
          }

          if (!resourceId || !isValidUUID(resourceId)) {
            console.error('Invalid resource id', resourceId);
            return;
          }

          expand(resourceId, 'assistant');
          e.preventDefault(); // Prevent the default action (optional)
        }
      }
    };

    div?.addEventListener('click', linkClickHandler);

    return () => {
      div?.removeEventListener('click', linkClickHandler);
    };
  }, [expand]);

  return (
    <RenderedContentWrapper
      isResourceList={isResourceList}
      ref={divRef}
      dangerouslySetInnerHTML={{ __html: markdownContent }}
    />
  );
};

export const FabricAvatar = styled(Avatar)`
  ${renderCssBasedOnColorScheme({
    dark: css`
      background-color: ${cssVar['color-bg-primary-reverse']};
    `,
    light: css`
      background-color: ${cssVar['color-bg-primary']};
    `,
  })}
`;

const ChatbotMessage: React.FC<{ message: ChatbotConversationMessage }> = ({
  message,
}): JSX.Element => {
  const user = useAuthStore((state) => state.user, shallow);

  const isUser = isChatbotUserMessage(message);
  const isAssistant = isChatbotAssistantMessage(message);

  const content =
    isUser && message.action?.type === ChatbotConversationUserActionType.SUMMARIZE_TEXT
      ? `Summarize highlight:\n_“${message.content}”_`
      : message.content;

  const firstParagraph = Boolean(message.action)
    ? content
    : content.substring(0, content.indexOf('\n'));
  const restOfMessage = Boolean(message.action)
    ? undefined
    : content.substring(content.indexOf('\n') + 1);

  const firstRenderedContent = firstParagraph.length > 0 ? firstParagraph : content;

  return (
    <Conversation.Item>
      <Conversation.ItemHeader>
        {isUser ? (
          <>
            <AvatarUser user={user} tooltip={undefined} size={26} />
            <Conversation.ItemHeaderName>{user?.name}</Conversation.ItemHeaderName>
          </>
        ) : (
          <>
            <FabricAvatar size={26}>
              <InvertedFabricIcon
                style={{
                  imageRendering: 'pixelated',
                }}
                width={20}
              />
            </FabricAvatar>
            <Conversation.ItemHeaderName>Fabric Assistant</Conversation.ItemHeaderName>
          </>
        )}
      </Conversation.ItemHeader>
      <div className="table clear-both w-full overflow-none">
        <ContentBlock
          data-message-id={message.id}
          className="rounded-lg group"
          role={message.role}
          action={message.action}
        >
          <RenderedContent content={firstRenderedContent} />
          {(firstParagraph && restOfMessage) ||
          (isAssistant && message.resourceIds?.length && !message.action) ? (
            <>
              {isAssistant &&
                message.resourceIds?.length > 0 &&
                message.resourceIds?.map((resourceId) => {
                  const renderTarget = document.querySelector(
                    `[data-message-id='${message.id}'] a[href="fabric://${resourceId}"]`,
                  )?.parentElement;
                  return (
                    <PortalPrepend target={renderTarget} key={resourceId}>
                      <ChatbotMessageFdoc resourceId={resourceId} />
                    </PortalPrepend>
                  );
                })}
              {firstParagraph && restOfMessage && (
                <RenderedContent
                  isResourceList={isAssistant && message.resourceIds?.length > 0}
                  content={restOfMessage}
                />
              )}
            </>
          ) : null}
        </ContentBlock>
      </div>
    </Conversation.Item>
  );
};

const ItemContainer = styled.div`
  width: calc(168px * 168 / 192);
  overflow: hidden;
  aspect-ratio: ${cssVar['preview-compact-card-aspect-ratio']};
`;
const ChatbotMessageFdoc: React.FC<{ resourceId: string }> = ({ resourceId }) => {
  const { fdoc: resource } = useQueryResourceDetail(resourceId);

  const expand = useShowExpandedFdoc();

  if (!resource) {
    return null;
  }

  return (
    <ItemContainer>
      <ResourcePreview resource={resource} onOpen={() => expand(resourceId, 'assistant')} />
    </ItemContainer>
  );
};

export default ChatbotMessage;

interface ContentBlockProps {
  role?: ChatbotMessageRole;
  action?: ChatbotConversationUserAction;
}

const ContentBlock = styled(Flex).attrs((props) => {
  return {
    direction: 'column',
    gap: 'elementsContainer',
    ...props,
  };
})<ContentBlockProps>`
  position: relative;
  padding-top: 0.5rem;
  color: ${cssVar['color-text-primary']};
  font-size: 0.8125rem;
  line-height: 1.25rem;
`;
